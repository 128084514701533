/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  Card,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  withStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { AddCircle, Close, Edit, FileCopy } from '@material-ui/icons';
import { styled } from '@material-ui/styles';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Business } from '../../models/User.model';
import {
  getPwaConfig,
  getvalues,
  saveFile,
  savePwaConfig
} from '../../services/pwaConfig.service';
import { getLoyaltySignUpValues } from '../../services/pwaLoyaltySignUp.service';
import PWABanner from '../Assets/images/Pwa_Config.svg';
import MenuCard from '../MenuCard/MenuCard';
import SettingsBreadCrumbs from '../SettingsBreadCrumbs/SettingsBreadCrumbs';
import AdditionalText from './AdditionalText/AdditionalText';
import { ReactComponent as Save } from './assets/save.svg';
import CustomMenuForm from './CustomMenuForm/CustomMenuForm';
import pwaConfigStyles from './PWAConfig.module.css';
import transformToFormData from './transformFormData';
import { ParentLink } from '../../utils/ParentLink';
import { getPermissionLabel } from './utils/permission-map';
import config from '../../config';
import { SurveyFormSelect } from '../Survey/SurveyFormSelect';
import { toast } from 'react-toastify';

export const LoginSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 50,
  height: 25,
  padding: 0,
  marginRight: '12%',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(25px)',
      color: 'white',
      '& + .MuiSwitch-track': {
        backgroundColor: '#F2755F',
        opacity: 1
      },
      '& .MuiSwitch-thumb': {
        width: 22,
        height: 22
      }
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1
  }
}));

export const SavedIcon = props => (
  <svg
    width={28}
    height={28}
    fill="none"
    style={{ margin: 12 }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m4.813 15.313 6.125 6.124 12.25-13.125"
      stroke={props.color}
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ImageUpload = ({
  label,
  image,
  pageWiseView,
  isEdit,
  menuItem,
  isHeader,
  headerFooter,
  setHeaderFooter,
  isMenu,
  setImage
}) => {
  const hiddenFileInput = useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const setHeader = newImage => {
    setHeaderFooter({
      ...headerFooter,
      [menuItem]: {
        headerImage: newImage,
        footerImage: headerFooter[menuItem].footerImage
      }
    });
  };

  const setFooter = newImage => {
    setHeaderFooter({
      ...headerFooter,
      [menuItem]: {
        headerImage: headerFooter[menuItem].headerImage,
        footerImage: newImage
      }
    });
  };

  const setHeaderOrFooter = e => {
    isHeader ? setHeader(e.target.files[0]) : setFooter(e.target.files[0]);
  };

  const settingImage = e => {
    isMenu ? setHeaderOrFooter(e) : setImage(e.target.files[0]);
  };

  const deleteHeader = () => {
    setHeaderFooter({
      ...headerFooter,
      [menuItem]: {
        headerImage: null,
        footerImage: headerFooter[menuItem].footerImage
      }
    });
  };

  const deleteFooter = () => {
    setHeaderFooter({
      ...headerFooter,
      [menuItem]: {
        headerImage: headerFooter[menuItem].headerImage,
        footerImage: null
      }
    });
  };

  const deleteHeaderOrFooter = () => {
    isHeader ? deleteHeader() : deleteFooter();
  };

  const deleteImage = () => {
    isMenu ? deleteHeaderOrFooter() : setImage();
  };

  const validateImage = e => {
    const imgSize = e.target?.files[0]?.size <= 300000;
    imgSize ? settingImage(e) : alert('Please Upload Image less than 300 kb ');
  };

  const renderUploadButton = () => {
    return (
      <Button
        style={
          (pageWiseView !== false ? { width: '80%' } : {},
          !isMenu ? { width: '120px' } : {})
        }
        color="primary"
        variant="contained"
        disabled={isEdit}
        onClick={handleClick}
      >
        Upload
      </Button>
    );
  };

  return (
    <div className={pageWiseView === false ? pwaConfigStyles.uploadImg : ''}>
      <div
        style={
          pageWiseView !== false ? { marginBottom: 15 } : { marginBottom: 0 }
        }
      >
        {label}
      </div>
      <div>
        {image ? (
          <div>
            <span style={{ color: 'lightgray' }}>
              {image?.name ? image.name.slice(0, 8) : image.slice(0, 15)}...
            </span>
            {!isEdit && (
              <IconButton onClick={deleteImage}>
                <Close style={{ color: 'black' }} />
              </IconButton>
            )}
            {isEdit && <SavedIcon color="#0D921B" />}
          </div>
        ) : (
          renderUploadButton()
        )}
      </div>
      <input
        className={pwaConfigStyles.imageInput}
        type="file"
        accept="image/png, image/jpeg, image/webp, image/svg+xml"
        ref={hiddenFileInput}
        onChange={e => {
          validateImage(e);
        }}
        onClick={event => {
          event.target.value = '';
        }}
      />
    </div>
  );
};

const Color = ({ label, setColor, setStyle, text, value, isEdit }) => {
  return (
    <div className={pwaConfigStyles.colorSelection}>
      <span className={pwaConfigStyles.label}>{label}</span>
      {text ? (
        <input
          type="color"
          value={value}
          disabled={isEdit && true}
          onChange={e => setColor(e.target.value)}
          style={setStyle}
        />
      ) : (
        <input
          type="color"
          value={value}
          onChange={e => setColor(e.target.value)}
          disabled={isEdit && true}
          style={setStyle}
        />
      )}
    </div>
  );
};

function PWAConfig() {
  const [pwaConfig, setPwaConfig] = useState();
  const [logo, setLogo] = useState();
  const [splashScreen, setSplashScreen] = useState();
  const [thankyouScreen, setThankyouScreen] = useState();
  const [headerFooter, setHeaderFooter] = useState({});
  const [menu, setMenu] = useState({});
  const [theme, setTheme] = useState('#5e72e4');
  const [sideNavText, setSideNavText] = useState('#ffffff');
  const [sidenavTheme, setSidenavTheme] = useState('#5e72e4');
  const [fontName, setfontName] = useState('');
  const [initialPagePwa, setInitialPagePwa] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [pageImage, setPageImage] = useState(false);
  const [navmenu, setNavMenu] = useState([]);
  const [font, setFont] = useState([]);
  const [show, setShow] = useState(false);
  const [viewUrl, setViewUrl] = useState(false);
  const [business, setBusiness] = useState(new Business());
  const [customHtmlMenu, setCustomHtmlMenu] = useState([]);
  const [isSelected, setIsSelected] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editMenuName, setEditMenuName] = useState('');
  const [editHtmlName, setEditHtmlName] = useState('');
  const [defaultMenuName, setDefaultMenuName] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [html, setHtml] = useState('');
  const [menuName, setMenuName] = useState('');
  const [permissions, setPermissions] = useState({});
  const [page, setPage] = useState('All Pages');
  const [additionalText, setAdditionalText] = useState({});
  const [isValidText, setIsValidText] = useState(true);
  const [profileSurvey, setProfileSurvey] = useState({ enabled: false });
  const [feedbackSurvey, setFeedbackSurvey] = useState({ enabled: false });
  const user = useCurrentUser();
  const link = `${config.pwaUrl}/register/${user?.tenant?.esKey}/${business?.name}`;

  const CustomRadio = withStyles({
    root: {
      '&$checked': {
        color: isEdit ? 'default' : '#F26E5F'
      }
    },
    checked: {}
  })(props => <Radio color="default" {...props} />);

  const FeatureSelect = ({
    label,
    fontName,
    setfontName,
    fontNames,
    isEdit
  }) => {
    return (
      <div>
        <span>
          <b>{label}</b>
        </span>
        <RadioGroup
          row
          style={{ marginTop: 10 }}
          name="fontName"
          value={fontName}
          onChange={event => {
            setfontName(event.target.value);
          }}
        >
          {_.map(fontNames, feature => {
            return (
              <FormControlLabel
                key={feature}
                value={feature}
                disabled={isEdit && true}
                control={<CustomRadio />}
                label={feature}
                style={{ marginRight: 25 }}
              />
            );
          })}
        </RadioGroup>
      </div>
    );
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) setBusiness(paramsBusiness);
    getvalues().then(response => {
      setFont(response.fontFamily);
      setNavMenu(response.menu);
    });
  }, [user]);

  const doesPermissionEnabled = (permission, permissionMap) =>
    _.includes(_.get(permissionMap, 'enabled', []), permission);

  const getPwaPermissionsMap = ({ permissions = {} }) => {
    const allPermissions = _.flattenDeep(_.concat(Object.values(permissions)));
    return allPermissions.reduce((acc, permission) => {
      acc[permission] = {
        name: permission,
        enabled: doesPermissionEnabled(permission, permissions)
      };
      return acc;
    }, {});
  };
  const switchState = stateValue => {
    if (stateValue === true || stateValue === 'true') {
      return true;
    } else if (stateValue === false || stateValue === 'false') {
      return false;
    }
    return false;
  };

  useEffect(() => {
    console.log(business);
    if (business?.id) {
      console.log(business);
      getPwaConfig(business?.id).then(res => {
        setPwaConfig(res);
        setPermissions(() => getPwaPermissionsMap(res));
        setMenu(res.menu);
        setCustomHtmlMenu(
          _.filter(_.values(res.menu), item => {
            return item.module === 'HTML';
          })
        );
        setAdditionalText(res.additionalText);
        setHeaderFooter({ ...res.modules });
        setLogo(res.logo);
        setSplashScreen(res.splashScreen?.background);
        setThankyouScreen(res.thankYouScreen?.background);
        setInitialPagePwa(res.initialModule);
        setTheme(res.palette?.primary);
        setSidenavTheme(res.palette.sideNav?.background);
        setSideNavText(res.palette.sideNav?.textColor);
        setProfileSurvey({
          id: Number(res.profileSurvey?.surveyId),
          name: res.profileSurvey?.surveyName,
          enabled: switchState(res.profileSurvey?.enabled)
        });
        setFeedbackSurvey({
          id: Number(res.feedbackSurvey?.surveyId),
          name: res.feedbackSurvey?.surveyName,
          enabled: switchState(res.feedbackSurvey?.enabled)
        });
        setfontName(res.typography?.fontFamily);
      });
      getLoyaltySignUpValues(business?.id).then(res => {
        if (res.length > 0) setViewUrl(true);
      });
    }
    // eslint-disable-next-line
  }, [user, business]);

  useEffect(() => {
    setIsSelected(isSelected =>
      navmenu.reduce((acc, val) => {
        acc[val.name] = Object.values(menu).some(el => el.name === val.name);
        return acc;
      }, isSelected)
    );
  }, [navmenu, menu]);

  useEffect(() => {
    setIsSelected(isSelected =>
      customHtmlMenu.reduce((acc, val) => {
        acc[val.name] = isSelected[val.name] ?? true;
        return acc;
      }, isSelected)
    );
  }, [customHtmlMenu]);

  useEffect(() => {
    setHeaderFooter(headerFooter =>
      _.keys(isSelected).reduce((acc, val) => {
        acc[
          val
            .trim()
            .toUpperCase()
            .replaceAll(' ', '_')
        ] = headerFooter[
          val
            .trim()
            .toUpperCase()
            .replaceAll(' ', '_')
        ] ?? {
          headerImage: null,
          footerImage: null
        };
        return acc;
      }, headerFooter)
    );
  }, [isSelected, headerFooter]);

  const convertToPascalCase = name => {
    return name
      .split(' ')
      .map(word => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');
  };
  const convertToName = name => {
    return convertToPascalCase(name.toLowerCase().replaceAll('_', ' '));
  };
  const convertToMenuName = name => {
    return name
      .trim()
      .toUpperCase()
      .replaceAll(' ', '_');
  };

  // const handleSignUp = () => {
  //   return (window.top.location.href =
  //     config.dashboardUrl + '/personify/loyalty-signUp');
  // };

  const copyLink = () => {
    const text = document.getElementById('url').innerText;
    const elem = document.createElement('textarea');
    document.body.appendChild(elem);
    elem.value = text;
    elem.select();
    document.execCommand('copy');
    const copiedValue = document.body.removeChild(elem);
    setTooltip(true);
    setTimeout(() => {
      setTooltip(false);
    }, 3000);
    return copiedValue;
  };

  const handleSwitch = e => {
    e.target.name = e.target.checked;
    setPageImage(e.target.checked);
  };

  const getKeyByValue = value => {
    return Object.keys(menu).find(key => menu[key].name === value);
  };

  const addToMenu = (menuArray, value, temp) => {
    menuArray.map(val => {
      if (val.name === value) {
        temp = {
          ...temp,
          [convertToMenuName(val.name)]: val
        };
        setMenu({ ...temp });
      }
    });
  };

  const deleteFromMenu = (menuArray, value) => {
    menuArray.map(val => {
      if (val.name === value) {
        setMenu(current => {
          const copy = { ...current };
          delete copy[getKeyByValue(val.name)];
          return copy;
        });
      }
    });
  };

  const handleChecked = (menuArray, value, checked) => {
    const temp = { ...menu };
    if (checked) {
      addToMenu(menuArray, value, temp);
    } else {
      deleteFromMenu(menuArray, value);
    }
  };

  const canSave = () => {
    return (
      (headerFooter['HOME']?.headerImage ? true : false) &&
      (headerFooter['HOME']?.footerImage ? true : false) &&
      !_.isEmpty(menu) &&
      isValidText
    );
  };
  const getAllPermissions = permissionMap =>
    _.flattenDeep(_.concat(Object.values(permissionMap))) || [];

  const getUpdatedPermissions = () => {
    const allPermissions = getAllPermissions(_.get(pwaConfig, 'permissions'));
    return allPermissions.reduce(
      (permissionMap, permission) => {
        if (permissions[permission]?.enabled)
          permissionMap.enabled.push(permission);
        else permissionMap.disabled.push(permission);
        return permissionMap;
      },
      {
        enabled: [],
        disabled: []
      }
    );
  };

  function getSurveyFormLink(token, withDataToken = false) {
    const host = config.dashboard;
    const pathName = '/customer/survey/form/';
    let link = host + pathName + token;
    if (withDataToken) {
      link = link + '?dataToken=';
    }
    return link;
  }

  const onSave = async () => {
    setIsSaving(true);
    const data = {
      menu,
      modules: {
        ...headerFooter
      },
      palette: {
        primary: theme,
        sideNav: {
          textColor: sideNavText,
          background: sidenavTheme
        }
      },
      initialModule: initialPagePwa,
      typography: {
        fontFamily: fontName
      },
      splashScreen: {
        background: splashScreen
      },
      thankYouScreen: {
        background: thankyouScreen
      },
      logo: logo,
      permissions: getUpdatedPermissions(),
      additionalText: additionalText,
      profileSurvey: {
        surveyId: profileSurvey?.id,
        surveyName: profileSurvey?.name,
        link: getSurveyFormLink(profileSurvey?.token, true),
        enabled: profileSurvey?.enabled
      },
      feedbackSurvey: {
        surveyId: feedbackSurvey?.id,
        surveyName: feedbackSurvey?.name,
        link: getSurveyFormLink(feedbackSurvey?.token, true),
        enabled: feedbackSurvey?.enabled
      }
    };
    // Didn't model since this screen will be revamped.
    if (profileSurvey?.enabled) {
      if (!profileSurvey?.id) {
        toast.error('Profile Survey cannot be empty');
        setIsSaving(false);
        return;
      }
    }
    // Didn't model since this screen will be revamped.
    if (feedbackSurvey?.enabled) {
      if (!feedbackSurvey?.id) {
        toast.error('Feedback Survey cannot be empty');
        setIsSaving(false);
        return;
      }
    }
    const payload = transformToFormData(data);
    await savePwaConfig(payload, business.id);
    setIsSaving(false);
    setShow(true);
    setIsEdit(true);
    setPageImage(false);
  };

  const editTogglePopUp = () => {
    setEditOpen(!editOpen);
  };

  const togglePopUp = () => {
    setOpen(!open);
  };

  const handleOnClose = () => {
    setIsEditing(false);
    setMenuName('');
    setHtml('');
    togglePopUp();
  };

  const handleEditOnClose = () => {
    setIsEditing(false);
    setEditMenuName('');
    setEditHtmlName('');
    editTogglePopUp();
  };

  const deleteCustomMenu = name => {
    _.remove(customHtmlMenu, value => {
      return value.name.toLowerCase() === name.toLowerCase();
    });
  };

  const onDelete = menuName => {
    delete isSelected[menuName];
    delete headerFooter[convertToMenuName(menuName)];
    handleChecked(customHtmlMenu, menuName, false);
    deleteCustomMenu(menuName);
    setIsEditing(!isEditing);
    editTogglePopUp();
  };

  const getCustomMenu = fileUrl => {
    return {
      name: menuName.trim(),
      path: fileUrl,
      module: 'HTML'
    };
  };

  const createCustomMenu = (name, customMenu) => {
    deleteCustomMenu(name); //removing menu with same name to avoid duplicate
    deleteCustomMenu(customMenu.name); //removing menu with same name to avoid duplicate
    setCustomHtmlMenu(customHtmlMenu.concat(customMenu));
  };

  const handleCreate = async val => {
    const fileUrl = await saveFile(html, user.tenant.esKey);
    const customMenu = getCustomMenu(fileUrl);
    const menuAttribute = convertToMenuName(menuName);

    createCustomMenu(customMenu.name, customMenu);
    setMenu({ ...menu, [menuAttribute]: customMenu });
    setIsSelected({ ...isSelected, [menuName]: true });
    setMenuName('');
    setHtml('');
    togglePopUp();
  };

  const onEdit = menuName => {
    setDefaultMenuName(menuName);
    const editMenu = _.filter(customHtmlMenu, item => {
      if (item.name === menuName) {
        return item;
      }
    });
    const htmlObject = {
      name: editMenu[0].path
    };
    setEditHtmlName(htmlObject);
    setEditMenuName(menuName);
    setIsEditing(!isEditing);
    setEditOpen(!editOpen);
  };

  const deleteMenu = defName => {
    const convertedMenuName = convertToMenuName(defName);
    delete menu[convertedMenuName];
  };

  const getUpdatedCustomMenu = async () => {
    let fileUrl;
    editHtmlName.name.includes(
      'https://rules.casaqa.ajira.tech/public-html/fashionfolks/'
    )
      ? (fileUrl = editHtmlName.name)
      : (fileUrl = await saveFile(editHtmlName, user.tenant.esKey));
    return {
      name: editMenuName.trim(),
      path: fileUrl,
      module: 'HTML'
    };
  };

  const updateIsSelected = defName => {
    const selected = isSelected[defName];
    delete isSelected[defName];
    isSelected[editMenuName] = selected;
  };

  const handleUpdate = async defName => {
    const customMenu = await getUpdatedCustomMenu();
    const menuAttribute = convertToMenuName(editMenuName);

    createCustomMenu(defName, customMenu);
    updateIsSelected(defName);

    deleteMenu(defName);
    setMenu({ ...menu, [menuAttribute]: customMenu });

    editTogglePopUp();
    setIsEditing(!isEditing);
  };

  function renderDeleteField(menuName) {
    return (
      <div
        style={{
          border: '1px solid #CF0B0B',
          padding: '3px 5px 0px 5px',
          borderRadius: '5px',
          cursor: 'pointer'
        }}
        onClick={() => onDelete(menuName)}
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
            fill="#CF0B0B"
          />
        </svg>
      </div>
    );
  }

  const handleMenuClick = e => {
    if (
      Object.keys(menu).length === 1 &&
      _.includes(_.keys(menu), convertToMenuName(e.target.name))
    )
      return;
    handleChecked(navmenu, e.target.name, !isSelected[e.target.name]);
    handleChecked(customHtmlMenu, e.target.name, !isSelected[e.target.name]);
    setIsSelected({
      ...isSelected,
      [e.target.name]: !isSelected[e.target.name]
    });
  };

  const renderPermissionToggle = ({ permission, handleToggle }) => {
    const { enabled, name } = permission;

    return (
      <div key={name} className={pwaConfigStyles.permissionsToggleContainer}>
        <p>{getPermissionLabel(name)}</p>
        <LoginSwitch
          checked={enabled}
          disabled={isEdit}
          name={name}
          onClick={handleToggle}
        />
      </div>
    );
  };

  const renderPermissions = () => {
    if (!_.has(pwaConfig, 'permissions')) return <></>;
    const allPermissions = getAllPermissions(pwaConfig.permissions);

    const handleToggle = ({ target }) => {
      setPermissions(prev => ({
        ...prev,
        [target.name]: { ...prev[target.name], enabled: target.checked }
      }));
    };

    return (
      <div className={pwaConfigStyles.permissionsContainer}>
        {allPermissions.map(permission =>
          renderPermissionToggle({
            permission: _.get(permissions, permission, {
              enabled: false,
              name: ''
            }),
            handleToggle
          })
        )}
      </div>
    );
  };

  const renderAdditionalText = () => {
    return (
      <AdditionalText
        page={page}
        setPage={setPage}
        additionalText={additionalText}
        setAdditionalText={setAdditionalText}
        isEdit={isEdit}
        isValidText={isValidText}
        setIsValidText={setIsValidText}
      />
    );
  };
  // const renderErrorToggle = () => {
  //   console.log(isValidInput);
  //   return <div>{'isValidInput'} </div>;
  // };
  return (
    <>
      {/* {renderErrorToggle()} */}
      <div>
        <img src={PWABanner} alt="" className={pwaConfigStyles.pwaBanner} />
      </div>
      <SettingsBreadCrumbs
        currentModuleName="Personify Configuration"
        fromModuleName="Module Settings"
        isModule={true}
      />
      <div className={pwaConfigStyles.container}>
        {show && (
          <div className={pwaConfigStyles.modal}>
            <div className={pwaConfigStyles.modalcontent}>
              <div className={pwaConfigStyles.flexDisplay}>
                <Save style={{ height: 45 }} />
                <h3 className={pwaConfigStyles.saveHead}>SAVED</h3>
              </div>

              <p className={pwaConfigStyles.saveContent}>
                PWA Configuration Changes Saved Successfully
              </p>

              <div className={pwaConfigStyles.flexDisplay}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#F2755F', color: 'white' }}
                  onClick={() => setShow(false)}
                >
                  OK
                </Button>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div className={pwaConfigStyles.headerRow}>
            {isEdit ? (
              <div className={pwaConfigStyles.headContainer}>
                <div className={pwaConfigStyles.content}>
                  <h1 className={pwaConfigStyles.head}>
                    Edit the configuration
                  </h1>
                  <p className={pwaConfigStyles.headContent}>
                    Enable the edit button to make changes
                  </p>
                </div>
                <Button
                  color="primary"
                  variant="contained"
                  // disabled={!canSave()}
                  onClick={() => setIsEdit(false)}
                  style={{ width: '150px' }}
                >
                  Edit
                </Button>
              </div>
            ) : (
              <div className={pwaConfigStyles.headContainer}>
                <div className={pwaConfigStyles.content}>
                  <h1 className={pwaConfigStyles.head}>
                    Save the configuration
                  </h1>
                  <p className={pwaConfigStyles.headContent}>
                    Click save button to save the configuration
                  </p>
                </div>
                {isSaving ? (
                  <div className={pwaConfigStyles.loader}></div>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!canSave()}
                    onClick={onSave}
                    style={{ width: '150px' }}
                  >
                    SAVE
                  </Button>
                )}
              </div>
            )}
          </div>
          <br />
          <div className={pwaConfigStyles.row}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={pwaConfigStyles.customContainer}>
                <span style={{ marginRight: '3%', marginTop: '1%' }}>
                  <b>Set Menu Options*</b>
                </span>
                {!isEditing ? (
                  <CustomMenuForm
                    open={open}
                    togglePopUp={togglePopUp}
                    menuName={menuName}
                    setMenuName={setMenuName}
                    html={html}
                    setHtml={setHtml}
                    fieldName={'Create'}
                    handleCreate={handleCreate}
                    handleOnClose={handleOnClose}
                    isEditing={isEditing}
                    renderDeleteField={renderDeleteField}
                    editMenuName={editMenuName}
                    defaultMenuName={defaultMenuName}
                    menu={menu}
                    customHtmlMenu={customHtmlMenu}
                    convertToMenuName={convertToMenuName}
                  />
                ) : (
                  <CustomMenuForm
                    open={editOpen}
                    togglePopUp={editTogglePopUp}
                    menuName={editMenuName}
                    setMenuName={setEditMenuName}
                    html={editHtmlName}
                    setHtml={setEditHtmlName}
                    fieldName={'Save'}
                    handleCreate={handleUpdate}
                    handleOnClose={handleEditOnClose}
                    isEditing={isEditing}
                    renderDeleteField={renderDeleteField}
                    editMenuName={editMenuName}
                    defaultMenuName={defaultMenuName}
                    menu={menu}
                    customHtmlMenu={customHtmlMenu}
                    convertToMenuName={convertToMenuName}
                  />
                )}
              </div>
            </div>

            <div className={pwaConfigStyles.menus}>
              {navmenu?.map(val => {
                return (
                  <MenuCard
                    key={val.name}
                    menuName={val.name}
                    isSelected={isSelected[val.name]}
                    isEdit={isEdit}
                    isCustom={false}
                    onClick={handleMenuClick}
                    onEdit={undefined}
                  />
                );
              })}
              {customHtmlMenu &&
                _.map(customHtmlMenu, val => {
                  return (
                    <MenuCard
                      key={val.name}
                      menuName={val.name}
                      isSelected={isSelected[val.name]}
                      isEdit={isEdit}
                      isCustom={true}
                      onClick={handleMenuClick}
                      onEdit={onEdit}
                    />
                  );
                })}
              {!isEdit && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={togglePopUp}
                  style={{
                    fontWeight: 'normal',
                    height: '37px'
                  }}
                >
                  <AddCircle
                    style={{
                      width: '16px',
                      height: '16px'
                    }}
                  />
                </Button>
              )}
            </div>

            <div>
              <span>
                <b>Set Pwa Landing Page</b>
              </span>
              <br />
              <RadioGroup
                row
                name="landingPage"
                value={initialPagePwa}
                onChange={e => {
                  setInitialPagePwa(e.target.value);
                }}
              >
                {menu &&
                  Object.values(menu).map((item, index) => {
                    if (item.module !== 'HTML') {
                      return (
                        <>
                          <FormControlLabel
                            key={index}
                            control={<CustomRadio key={item.module} />}
                            label={item.name}
                            disabled={isEdit && true}
                            value={item.module}
                            checked={
                              initialPagePwa && initialPagePwa === item.module
                            }
                          />
                        </>
                      );
                    }
                  })}
              </RadioGroup>
            </div>
            <br />

            <div className={pwaConfigStyles.headerFooterDiv}>
              <span>
                <b> Do you want to set header and footer for specific pages?</b>
              </span>
              <div style={{ marginRight: '12%' }}>
                <LoginSwitch
                  checked={pageImage}
                  name="setPageWiseImage"
                  onClick={handleSwitch}
                  disabled={isEdit && true}
                />
              </div>
            </div>
            {pageImage === false ? (
              <Card
                style={{ padding: 20, boxShadow: '-2px 0px 25px 6px #EAEEF2' }}
              >
                <span>
                  <b>Common Header and Footer*</b>
                </span>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 20
                  }}
                >
                  <ImageUpload
                    label="Set Header ( aspect ratio - 2:1 )"
                    image={headerFooter['HOME']?.headerImage ?? null}
                    pageWiseView={pageImage}
                    isEdit={isEdit}
                    menuItem={'HOME'}
                    isHeader={true}
                    headerFooter={headerFooter}
                    setHeaderFooter={setHeaderFooter}
                    isMenu={true}
                  />

                  <ImageUpload
                    label="Set Footer ( aspect ratio - 2:1 )"
                    image={headerFooter['HOME']?.footerImage ?? null}
                    pageWiseView={pageImage}
                    isEdit={isEdit}
                    menuItem={'HOME'}
                    isHeader={false}
                    headerFooter={headerFooter}
                    setHeaderFooter={setHeaderFooter}
                    isMenu={true}
                  />
                </div>
              </Card>
            ) : (
              <Grid container style={{ margin: 0 }}>
                {headerFooter &&
                  Object.keys(headerFooter).map(menuItem => {
                    if (menuItem !== 'LOYALTY_SIGNUP') {
                      return (
                        <Grid xs={6}>
                          <Card
                            style={{
                              padding: 20,
                              boxShadow: '-2px 0px 25px 6px #EAEEF2',
                              margin: 10,
                              paddingLeft: 50,
                              paddingRight: 50
                            }}
                          >
                            <div style={{ textAlign: 'center' }}>
                              <b>{convertToName(menuItem)}</b>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: 20
                              }}
                            >
                              <ImageUpload
                                label="Set Header ( aspect ratio - 2:1 )"
                                image={headerFooter[menuItem].headerImage}
                                menuItem={menuItem}
                                isHeader={true}
                                headerFooter={headerFooter}
                                setHeaderFooter={setHeaderFooter}
                                isMenu={true}
                              />
                              <ImageUpload
                                label="Set Footer ( aspect ratio - 2:1 )"
                                image={headerFooter[menuItem].footerImage}
                                menuItem={menuItem}
                                isHeader={false}
                                headerFooter={headerFooter}
                                setHeaderFooter={setHeaderFooter}
                                isMenu={true}
                              />
                            </div>
                          </Card>
                        </Grid>
                      );
                    }
                  })}
              </Grid>
            )}
          </div>
          <br />
          {renderAdditionalText()}
          <br />
          <div className={pwaConfigStyles.row}>
            <span>
              <b>Set the Screen and Logo</b>
            </span>
            <Card
              style={{
                padding: 20,
                boxShadow: '-2px 0px 25px 6px #EAEEF2',
                marginTop: 20
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 20,
                  marginRight: 90,
                  marginLeft: 90
                }}
              >
                <ImageUpload
                  label="Set Splash Screen"
                  image={splashScreen}
                  setImage={setSplashScreen}
                  isEdit={isEdit}
                  isMenu={false}
                />

                <ImageUpload
                  label="Set Thank You Screen"
                  image={thankyouScreen}
                  setImage={setThankyouScreen}
                  isEdit={isEdit}
                  isMenu={false}
                />

                <ImageUpload
                  label={`Set PWA Logo (300px*300px)`}
                  image={logo}
                  setImage={setLogo}
                  isEdit={isEdit}
                  isMenu={false}
                />
              </div>
            </Card>
          </div>
          <br />

          <div className={pwaConfigStyles.row}>
            <FeatureSelect
              label="Choose Font"
              fontName={fontName}
              setfontName={setfontName}
              fontNames={font}
              isEdit={isEdit}
            />
          </div>
          <br />

          <div className={pwaConfigStyles.row}>
            <div style={{ marginBottom: 10 }}>
              <b>Set Theme</b>
            </div>
            <Color
              label="Set Primary Color theme"
              value={theme}
              setColor={setTheme}
              setStyle={{
                height: 21,
                padding: 0,
                border: '5px solid #D9D9D9',
                borderRadius: 3
              }}
              isEdit={isEdit}
            />
            <Color
              label="Set Side-Nav theme"
              value={sidenavTheme}
              setColor={setSidenavTheme}
              setStyle={{
                height: 21,
                padding: 0,
                border: '5px solid #D9D9D9',
                borderRadius: 3
              }}
              isEdit={isEdit}
            />
            <Color
              text="true"
              label="Set Side-Nav Text Theme"
              value={sideNavText}
              setColor={setSideNavText}
              setStyle={{
                height: 21,
                padding: 0,
                border: '5px solid #D9D9D9',
                borderRadius: 3
              }}
              isEdit={isEdit}
            />
          </div>
          <br />

          {permissions && (
            <div className={pwaConfigStyles.row}>
              <div style={{ marginBottom: 10 }}>
                <b>Permissions</b>
              </div>
              {renderPermissions()}
            </div>
          )}

          <br />
          <div className={pwaConfigStyles.rowhead}>
            <b>Loyalty Signup</b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isEdit ? (
                <IconButton
                  component={ParentLink}
                  to="/personify/loyalty-signUp"
                >
                  <Edit />
                </IconButton>
              ) : (
                <Tooltip
                  title={
                    'Save the current changes and then edit loyalty signup'
                  }
                >
                  <IconButton>
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          <br />
          <div className={pwaConfigStyles.row}>
            <div style={{ display: 'flex' }}>
              <b>Profile Survey Form</b>
              <div style={{ margin: '0 4px' }}>
                <LoginSwitch
                  checked={profileSurvey.enabled}
                  name={'Profile Survey'}
                  disabled={isEdit && true}
                  onClick={event =>
                    setProfileSurvey({
                      ...profileSurvey,
                      enabled: event.target.checked
                    })
                  }
                />
              </div>
            </div>
            <div>
              <SurveyFormSelect
                onChange={survey =>
                  setProfileSurvey({ ...profileSurvey, ...survey })
                }
                defaultValue={profileSurvey}
                disabled={!profileSurvey?.enabled || (isEdit && true)}
                selectedBusinessId={business?.id}
              />
            </div>
          </div>
          <br />
          <div className={pwaConfigStyles.row}>
            <div style={{ display: 'flex' }}>
              <b>Feedback Survey Form</b>
              <div style={{ margin: '0 4px' }}>
                <LoginSwitch
                  checked={feedbackSurvey.enabled}
                  name={'Feedback Survey'}
                  disabled={isEdit && true}
                  onClick={event =>
                    setFeedbackSurvey({
                      ...feedbackSurvey,
                      enabled: event.target.checked
                    })
                  }
                />
              </div>
            </div>
            <div>
              <SurveyFormSelect
                onChange={survey =>
                  setFeedbackSurvey({ ...feedbackSurvey, ...survey })
                }
                defaultValue={feedbackSurvey}
                disabled={!profileSurvey?.enabled || (isEdit && true)}
                selectedBusinessId={business?.id}
              />
            </div>
          </div>
          <br />
          {viewUrl && (
            <div className={pwaConfigStyles.rowhead}>
              <b>Loyalty Signup URL</b>
              <div style={{ display: 'flex' }}>
                <p id="url">{link}</p>
                <Tooltip title={tooltip ? 'Copied' : 'Copy'}>
                  <FileCopy
                    style={{
                      color: 'grey',
                      cursor: 'pointer',
                      marginLeft: '20px'
                    }}
                    onClick={copyLink}
                  />
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PWAConfig;
