import React, { FC, useContext, useEffect, useState } from 'react';
import './CheckoutForm.css';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import LoginForm from './LoginForm';
import { isEcomUserLoggedIn } from '../Utiles/FlipbookUtiles';
import { UserContext } from '../../../hooks/UserContext';
import _ from 'lodash';
import AddressForm from './AddressForm';
import {
  createAddress,
  updateAddressWithOrder
} from '../../../services/ecomCart.service';
import Snackbar from '@material-ui/core/Snackbar';
import cancelIcon from '../../Assets/images/icons/cancel_icon.svg';
import Payment from './Payment';
import InvalidCart from './InvalidCart';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '@media (min-width: 600px)': {
        width: '100%'
      }
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);

interface CheckoutFormProps {
  setCartItems: (data: any) => void;
  handleProductPopUpClose: any;
  cartItems: any;
  allProducts: any;
  storeDetails: any;
  checkOutSteps: any;
  currentStore: any;
}

export const CheckoutForm: FC<CheckoutFormProps> = ({
  setCartItems,
  handleProductPopUpClose,
  cartItems,
  allProducts,
  storeDetails,
  checkOutSteps,
  currentStore
}) => {
  const storeCode = _.get(currentStore, 'store', '');
  const classes = useStyles();
  const [orderPaymentResponse, setOrderPaymentResponse] = useState<any>(
    undefined
  );
  const stepVal = isEcomUserLoggedIn() ? 1 : 0;
  const [activeStep, setActiveStep] = React.useState(stepVal);
  const [isNextEnabled, setIsNextEnabled] = React.useState(true);
  const [steps] = React.useState(checkOutSteps);
  const [mobileNumber, setMobileNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [addressData, setAddressData]: any = React.useState();
  const [skipAddressCreation, setSkipAddressCreation] = React.useState(false);
  const [showNavigationBtn, setShowNavigationBtn] = React.useState(true);
  const userInfo = _.get(useContext(UserContext), 'userInfo');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [showNextOrFinish, setShowNextOrFinish] = React.useState(true);
  const { business } = useContext(BusinessUnitContext);
  const businessId = business.id;

  useEffect(() => {
    const otpStep = 0;
    if (stepVal === otpStep) {
      setShowNavigationBtn(false);
    }
  }, [stepVal]);

  const handleNext = () => {
    const nextStepVal = activeStep + 1;
    const PaymentStep = 2;
    if (nextStepVal === PaymentStep && !skipAddressCreation) {
      createAddressInEcom();
    } else if (nextStepVal === PaymentStep && skipAddressCreation) {
      updateAddressWithEcomOrder();
    } else {
      activeStep === steps.length - 1
        ? setActiveStep(activeStep)
        : setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === -1) {
      setActiveStep(2);
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const openErrorMessage = (command: any) => {
    setErrorMessage(_.get(command, 'error', 'Something went wrong'));
    setOpen(true);
  };
  function createAddressInEcom() {
    createAddress({ address: addressData }, businessId).then(
      res => {
        if (!_.has(res, 'error')) {
          updateAddressWithEcomOrder();
        } else {
          openErrorMessage(res);
        }
      },
      err => {
        openErrorMessage(err);
      }
    );
  }

  function updateAddressWithEcomOrder() {
    const addressForOrder = {
      order: {
        email: email,
        // eslint-disable-next-line @typescript-eslint/camelcase
        bill_address_attributes: addressData,
        // eslint-disable-next-line @typescript-eslint/camelcase
        ship_address_attributes: addressData
      }
    };
    updateAddressWithOrder(addressForOrder).then((r: any) => {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    });
  }
  function handleClose() {
    setOpen(false);
  }

  return (
    <div className={'checkout-form-container'}>
      <div className={'popup-header-section popup-no-header-checkout'}>
        <img
          className={'popup-close-section'}
          src={cancelIcon}
          alt={'close'}
          style={{ margin: '1rem' }}
          onClick={handleProductPopUpClose}
        />
      </div>
      <div className={'stepper-container'}>
        <div className={`${classes.root}`}>
          {activeStep >= 0 && (
            <Stepper activeStep={activeStep} className={'steps-container'}>
              {steps.map((label: any, index: number) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: { optional?: React.ReactNode } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
          <div className={'text-center h-100'}>
            {activeStep === 0 && (
              <div>
                <LoginForm
                  setCartItems={setCartItems}
                  tenantName={_.get(userInfo, 'esKey', '')}
                  setSkipLoginStep={e => setActiveStep(e)}
                  setMobileNumber={setMobileNumber}
                  mobileNumber={mobileNumber}
                  setShowNavigationBtn={setShowNavigationBtn}
                  setIsNextEnabled={setIsNextEnabled}
                  storeCode={storeCode}
                />
              </div>
            )}
            {activeStep === 1 && (
              <div>
                <AddressForm
                  mobileNumber={mobileNumber}
                  setIsNextEnabled={setIsNextEnabled}
                  setAddressData={setAddressData}
                  email={email}
                  setMobileNumber={setMobileNumber}
                  setEmail={setEmail}
                  addressData={addressData}
                  setSkipAddressCreation={setSkipAddressCreation}
                  setShowNextOrFinish={setShowNextOrFinish}
                />
              </div>
            )}
            {activeStep >= 2 && (
              <div>
                <Payment
                  tenantName={_.get(userInfo, 'esKey', '')}
                  userDetails={{ mobile: mobileNumber, email: email }}
                  address={addressData}
                  setCartItems={setCartItems}
                  setShowNextOrFinish={setShowNextOrFinish}
                  cartItems={cartItems}
                  allProducts={allProducts}
                  setActiveStep={setActiveStep}
                  storeDetails={storeDetails}
                  handleProductPopUpClose={handleProductPopUpClose}
                  storeCode={storeCode}
                  setOrderPaymentResponse={setOrderPaymentResponse}
                  setShowNavigationButton={setShowNavigationBtn}
                />
              </div>
            )}
            {activeStep === -1 && (
              <div className={'h-100'}>
                <InvalidCart
                  response={orderPaymentResponse}
                  storeDetails={storeDetails}
                  currentStore={currentStore}
                  invalidCartCta={() => {
                    setActiveStep(2);
                    setShowNavigationBtn(true);
                  }}
                />
              </div>
            )}
            <div>
              {showNavigationBtn && (
                <div>
                  <Button
                    disabled={activeStep === 0}
                    color="primary"
                    onClick={handleBack}
                    className={`${classes.button} step-nav-btn`}
                  >
                    Back
                  </Button>
                  {showNextOrFinish && (
                    <Button
                      disabled={!isNextEnabled}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={`${classes.button} step-nav-btn`}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        onClose={handleClose}
        message={errorMessage}
        autoHideDuration={1500}
      />
    </div>
  );
};

export default CheckoutForm;
