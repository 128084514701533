import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import _ from 'lodash';
import useCurrentUser from '../../hooks/useCurrentUser';
import ConfigService from '../../services/config.service';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import styled from '@material-ui/core/styles/styled';
import Autocomplete from '@material-ui/lab/Autocomplete';
import productMasterService from '../../services/productMaster.service';
import loyaltyService from '../../services/loyalty.service';
import { Business } from '../../models/User.model';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ParentLink } from '../../utils/ParentLink';
import '../LoyaltyConfig/index.scss';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Typography } from '@material-ui/core';
import SettingsBreadCrumbs from '../SettingsBreadCrumbs/SettingsBreadCrumbs';
import { toast } from 'react-toastify';

const BorderedTableCell = styled(TableCell)({
  border: '1px solid #CFCFCF !important',
  backgroundColor: '#F3F3F3'
});
const BodyTableCell = styled(TableCell)({
  border: '1px solid #CFCFCF !important',
  backgroundColor: '#F3F3F3',
  padding: '3px 10px'
});

export default function LoyaltyException(props) {
  const user = useCurrentUser();
  const [offerLoyaltyType, setOfferLoyaltyType] = useState();
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [business, setBusiness] = useState(new Business());
  const [exceptionRules, setExceptionRules] = useState([]);
  const [blockLoyaltyOnTax, setBlockLoyaltyOnTax] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [levelLoading, setLevelLoading] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [tierNames, setTierNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [
    selectedExceptionRuleIndices,
    setSelectedExceptionRuleIndices
  ] = useState(new Set());
  const [loyaltyConfig, setLoyaltyConfig] = useState({});
  const [search, setSearch] = useState({});
  const [searchedItemInList, setSearchedItemInList] = useState([]);

  const resetOfferLoyalty = () => {
    setOfferLoyaltyType('full_loyalty');
  };
  const saveLoyaltyConfig = () => {
    try {
      if (business.id === undefined) {
        alert('Error: Business unit is missing!');
        return;
      }
      setDisableSaveBtn(true);
      const filter = { businessUnitId: business.id };
      const configObj = {
        ...loyaltyConfig,
        offerLoyaltyType: offerLoyaltyType,
        loyaltyExceptions: exceptionRules,
        blockLoyaltyOnTax: blockLoyaltyOnTax
      };
      ConfigService.saveLoyaltyConfig(filter, configObj).then(resp => {
        // setDisableSaveBtn(false);
        if (resp.status === 'success') {
          alert('Loyalty configuration saved successfully');
        }
      });
    } catch (err) {
      setDisableSaveBtn(false);
      console.error(err);
    }
  };
  const getLoyaltyConfig = useCallback(() => {
    try {
      if (business.id === undefined) {
        alert('Error: Business unit is missing!');
        return;
      }
      const filter = { businessUnitId: business.id };
      setIsLoading(true);
      ConfigService.getLoyaltyConfig(filter).then(resp => {
        setLoyaltyConfig(resp);
        if (resp) {
          if (resp.offerLoyaltyType) {
            setOfferLoyaltyType(resp.offerLoyaltyType);
          } else {
            resetOfferLoyalty();
          }
          if (resp.loyaltyExceptions) {
            setExceptionRules(resp.loyaltyExceptions);
          }
          if (resp.blockLoyaltyOnTax) {
            setBlockLoyaltyOnTax(!!resp.blockLoyaltyOnTax);
          }
          setIsLoading(false);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }, [business]);

  useEffect(() => {
    if (!business.id) return;
    loyaltyService
      .getTierConfigs(business)
      .then(res => {
        setTiers(res);
      })
      .catch(e => console.error(e));
  }, [business]);

  useEffect(() => {
    setTierNames(_.map(tiers, tier => _.get(tier, 'tierType.name')));
  }, [tiers]);

  const productHierarchy = business.productHierarchyNew;
  const defaultException = {
    hierarchy: {
      ..._.reduce(
        productHierarchy,
        (acc, value) => {
          acc[value.casaFieldName] = null;
          return acc;
        },
        {}
      )
    },
    loyaltyOffset: _.map(tiers, tier => ({
      tierId: _.get(tier, 'tierTypeId'),
      percentage: _.get(tier, 'loyaltyOffset', 0)
    })),
    offerLoyaltyOffset: _.map(tiers, tier => ({
      tierId: _.get(tier, 'tierTypeId'),
      percentage: _.get(tier, 'loyaltyOffset', 0)
    }))
  };

  const headCells = [
    {
      id: 'select',
      content: <Checkbox disabled />
    },
    ..._.map(productHierarchy, level => ({
      id: level,
      content: level.displayName
    })),
    {
      id: 'pointsPer100',
      content: 'Points Earned Per Rs. 100',
      subHeadCells: _.map(tierNames, tierName => ({
        id: `pointsPer100${tierName}`,
        content: tierName
      }))
    },
    {
      id: 'pointsPer100OnOffer',
      content: 'Points Earned Per Rs. 100 on discount',
      subHeadCells: _.map(tierNames, tierName => ({
        id: `pointsPer100onOffer${tierName}`,
        content: tierName
      }))
    }
  ];

  const onOpen = (level, exceptionRule) => {
    setDropdownOptions([]);
    setLevelLoading(true);
    // transform hierarchy value into array(value) as filterProductFields expects array of values
    const filterJson = _.transform(
      _.get(exceptionRule, 'hierarchy', {}),
      (accum, val, key) => {
        if (val) accum[key] = [val];
      },
      {}
    );
    productMasterService
      .filterProductFields(business, level, filterJson)
      .then(res => {
        setDropdownOptions(res.values);
        setLevelLoading(false);
      });
  };

  const addExceptionRule = () => {
    setDisableSaveBtn(false);
    setSelectedExceptionRuleIndices(new Set());

    setExceptionRules([...exceptionRules, defaultException]);
  };

  const deleteSelectedExceptionRules = () => {
    const newArr = _.filter(exceptionRules, (val, index) => {
      const includesBool = !selectedExceptionRuleIndices.has(index);
      return includesBool;
    });
    setDisableSaveBtn(false);
    setSelectedExceptionRuleIndices(new Set());
    setExceptionRules(newArr);
  };

  const markExceptionRuleCheck = index => e => {
    const newSet = new Set(selectedExceptionRuleIndices);
    e.target.checked ? newSet.add(index) : newSet.delete(index);
    setSelectedExceptionRuleIndices(newSet);
  };

  const dropdownChange = (index, level) => (event, value) => {
    const newArr = [...exceptionRules];
    newArr[index]['hierarchy'][level] = value;
    const subLevels = productHierarchy.slice(
      _.findIndex(
        productHierarchy,
        product => product.casaFieldName === level
      ) + 1
    );
    for (const subLevel of subLevels) {
      newArr[index]['hierarchy'][subLevel.casaFieldName] = null;
    }
    setDisableSaveBtn(false);
    setExceptionRules(newArr);
  };

  const onLoyaltyOffsetChange = index => event => {
    if (Number(event.target.value) > Number(event.target.max)) return;
    const loyaltyOffsetType = 'loyaltyOffset';
    const newArr = [...exceptionRules];
    const baseTierLoyaltyOffset = _.get(
      _.find(tiers, ['tierType.stepNumber', 1]),
      'loyaltyOffset'
    );
    newArr[index][loyaltyOffsetType] = _.map(tiers, (tier, index) => ({
      tierId: _.get(tier, 'tierTypeId'),
      percentage:
        index === 0
          ? Number(event.target.value).toString()
          : (
              Math.round(
                Number(event.target.value) *
                  (_.get(tier, 'loyaltyOffset') / baseTierLoyaltyOffset) *
                  100
              ) / 100
            ).toFixed(2)
    }));
    setDisableSaveBtn(false);
    setExceptionRules(newArr);
  };

  const onOfferLoyaltyOffsetChange = (index, tierIndex) => event => {
    if (Number(event.target.value) > Number(event.target.max)) return;
    const loyaltyOffsetType = 'offerLoyaltyOffset';
    const newArr = [...exceptionRules];
    newArr[index][loyaltyOffsetType] = _.map(
      newArr[index][loyaltyOffsetType],
      (tierConfig, i) => ({
        tierId: _.get(tierConfig, 'tierId'),
        percentage:
          i === tierIndex
            ? Number(event.target.value).toString()
            : _.get(tierConfig, 'percentage')
      })
    );
    setDisableSaveBtn(false);
    setExceptionRules(newArr);
  };

  const isDropdownDisabled = (currentLevel, exceptionRule) => {
    const currentLevelIndex = _.findIndex(
      productHierarchy,
      product => product.casaFieldName === currentLevel
    );

    if (currentLevelIndex === 0) return false;

    // map values of exceptionRule by hierarchy to bool representing whether level is defined or not
    const getIsDefinedArray = () =>
      _.map(
        productHierarchy,
        level => !!_.get(exceptionRule, `hierarchy.${level.casaFieldName}`)
      );

    const lastDefinedLevelIndex = _.lastIndexOf(getIsDefinedArray(), true);
    return currentLevelIndex > lastDefinedLevelIndex + 1;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) setBusiness(paramsBusiness);
  }, [user]);
  useEffect(() => {
    if (business.id && business.id !== 0) {
      getLoyaltyConfig();
    }
  }, [business.id, getLoyaltyConfig]);

  const validateSearch = searchedData => {
    const matchedProduct = [];
    exceptionRules.forEach(product => {
      const data = Object.entries(product.hierarchy).every(
        ([key, value]) => value === searchedData?.[key] || value === null
      );
      matchedProduct.push(data);
    });
    const noOneMatched = matchedProduct.every(item => item === false);
    if (noOneMatched) {
      toast.error(
        `No SKUs match the configuration. Please check at CASA product Master`
      );
    }
    setSearchedItemInList(matchedProduct);
  };

  const handleTrack = e => {
    if (e.key === 'Enter' && e.target.value) {
      ConfigService.searchLoyaltyExceptionConfig(e.target.value, business.id)
        .then(resp => {
          const hierarchy = productHierarchy.map(item => item.casaFieldName);
          const searchResult = resp.data.masterData;
          let search = {};
          hierarchy.forEach(item => {
            search = { ...search, [item]: searchResult?.[item] };
          });
          setSearch(search);
          validateSearch(search);
        })
        .catch(err => {
          setSearch({});
          toast.error('No such SKU key');
        });
    }
  };

  const addUrlParams = link => {
    const urlParams = new URLSearchParams(window.location.search);
    return `${link}?bu=${urlParams.get('bu')}`;
  };

  return (
    <>
      <SettingsBreadCrumbs
        currentModuleName="Retensee Configuration"
        fromModuleName="Module Settings"
        isModule={true}
      />
      {isLoading ? (
        <CircularProgress
          color="inherit"
          style={{ height: 20, width: 20, marginLeft: 3 }}
        />
      ) : (
        <>
          <div className={'retenseeContainer'}>
            <ParentLink
              to={addUrlParams('/loyalty-tiers')}
              className={'heading'}
            >
              Loyalty Tier
            </ParentLink>
            <ParentLink
              to={addUrlParams('/loyalty-config')}
              className={'heading'}
            >
              Loyalty Offer
            </ParentLink>
            <ParentLink
              to={addUrlParams('/loyalty-exception')}
              className={'heading activeHeading'}
            >
              Loyalty exclusions
            </ParentLink>
            <ParentLink
              to={addUrlParams('/ad-hoc-points')}
              className={'heading'}
            >
              Ad Hoc Points
            </ParentLink>
            <ParentLink
              to={addUrlParams('/loyalty-redeem-configuration')}
              className={'heading'}
            >
              Loyalty Redemption
            </ParentLink>
            <ParentLink
              to={addUrlParams('/referral-configuration')}
              className={'heading'}
            >
              Referral
            </ParentLink>
          </div>
          <div style={{ margin: '3% 10% 0%' }}>
            <div
              className="configHeading"
              style={{
                fontSize: '20px',
                margin: '25px',
                textAlign: 'center',
                color: '#f26e5f',
                fontFamily: 'nunito'
              }}
            >
              Loyalty Configuration Rules on Product
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <div style={{ marginTop: '6%' }}>
                <TextField
                  onKeyDown={handleTrack}
                  variant="outlined"
                  label="Search"
                  size="small"
                />
              </div>
              <div style={{ display: 'flex', gap: '20px', marginTop: '6%' }}>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={addExceptionRule}
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={deleteSelectedExceptionRules}
                  disabled={_.isEmpty(selectedExceptionRuleIndices)}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </div>
            </div>
            <div
              style={{
                marginLeft: '0px',
                marginTop: '25px',
                width: '100%',
                overflow: 'auto',
                padding: '1rem'
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {_.map(headCells, (headCell, index) => (
                      <BorderedTableCell
                        key={`${index}_${headCell.id.casaFieldName}`}
                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                        rowSpan={headCell.subHeadCells ? 1 : 2}
                        colSpan={
                          headCell.subHeadCells
                            ? headCell.subHeadCells.length
                            : 1
                        }
                      >
                        {headCell.content}
                      </BorderedTableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    {_.map(headCells, headCell => {
                      return _.map(headCell.subHeadCells, subHeadCell => (
                        <BorderedTableCell
                          key={subHeadCell.id}
                          style={{ fontWeight: 'bold', textAlign: 'center' }}
                        >
                          {subHeadCell.content}
                        </BorderedTableCell>
                      ));
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(exceptionRules, (exceptionRule, index) => (
                    <TableRow key={index}>
                      <BodyTableCell style={{ textAlign: 'center' }}>
                        <Checkbox
                          color={'primary'}
                          key={index}
                          checked={selectedExceptionRuleIndices.has(index)}
                          onChange={markExceptionRuleCheck(index)}
                        />
                      </BodyTableCell>
                      {_.map(productHierarchy, level => {
                        return (
                          <BodyTableCell
                            key={`${index}_${level.casaFieldName}`}
                          >
                            <Autocomplete
                              style={{
                                minWidth: 150,
                                color: 'red !important'
                              }}
                              renderOption={option => (
                                <Typography
                                  style={{
                                    fontFamily: 'Nunito',
                                    fontSize: 14,
                                    fontWeight: 600
                                  }}
                                >
                                  {option}
                                </Typography>
                              )}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    style: {
                                      fontFamily: 'Nunito',
                                      fontSize: 16,
                                      fontWeight: 600,
                                      color: !Object.keys(search).length
                                        ? 'black'
                                        : searchedItemInList[index]
                                        ? '#F26E5F'
                                        : 'gray'
                                    }
                                  }}
                                  placeholder={'Select category'}
                                />
                              )}
                              options={dropdownOptions}
                              disabled={isDropdownDisabled(
                                level.casaFieldName,
                                exceptionRule
                              )}
                              loading={levelLoading}
                              onOpen={e => {
                                onOpen(level.casaFieldName, exceptionRule);
                              }}
                              value={_.get(
                                exceptionRule,
                                `hierarchy.${level.casaFieldName}`,
                                ''
                              )}
                              onChange={dropdownChange(
                                index,
                                level.casaFieldName
                              )}
                            />
                          </BodyTableCell>
                        );
                      })}
                      {_.map(
                        exceptionRule.loyaltyOffset,
                        (loyaltyOffsetObj, loyaltyOffsetIndex) => (
                          <BorderedTableCell
                            key={`${index}_loyaltyOffset_${loyaltyOffsetIndex}`}
                          >
                            <TextField
                              type={'number'}
                              inputProps={{
                                min: 0,
                                max: 100,
                                style: {
                                  color: !Object.keys(search).length
                                    ? 'black'
                                    : searchedItemInList[index]
                                    ? '#F26E5F'
                                    : 'gray'
                                }
                              }}
                              style={{ minWidth: 50 }}
                              value={_.get(loyaltyOffsetObj, 'percentage')}
                              onChange={onLoyaltyOffsetChange(index, false)}
                              disabled={loyaltyOffsetIndex > 0}
                            />
                          </BorderedTableCell>
                        )
                      )}
                      {_.map(
                        exceptionRule.offerLoyaltyOffset,
                        (loyaltyOffsetObj, loyaltyOffsetIndex) => (
                          <BorderedTableCell
                            key={`${index}_offerLoyaltyOffset_${loyaltyOffsetIndex}`}
                          >
                            <TextField
                              type={'number'}
                              inputProps={{
                                min: 0,
                                max: 100,
                                style: {
                                  color: !Object.keys(search).length
                                    ? 'black'
                                    : searchedItemInList[index]
                                    ? '#F26E5F'
                                    : 'gray'
                                }
                              }}
                              style={{ minWidth: 50 }}
                              value={_.get(loyaltyOffsetObj, 'percentage')}
                              onChange={onOfferLoyaltyOffsetChange(
                                index,
                                loyaltyOffsetIndex
                              )}
                              // disabled={loyaltyOffsetIndex > 0}
                            />
                          </BorderedTableCell>
                        )
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            <Button
              style={{
                height: '40px',
                width: '100px',
                top: '20px',
                fontSize: '16px'
              }}
              variant={'contained'}
              color={'primary'}
              onClick={saveLoyaltyConfig}
              disabled={disableSaveBtn}
              disableElevation
            >
              Save
            </Button>
          </div>
        </>
      )}
    </>
  );
}
