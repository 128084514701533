import React, { FC, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import FlipbookContainer from './FlipbookContainer';
import flipbookV2Service from '../../services/Flipbook/flipbookV2.service';
import { getStores, getStoresByToken } from '../../services/store.service';

import { CircularProgress } from '@material-ui/core';
import { BusinessUnitContext } from '../OfferDefinition/BusinessUnitContext';
import { UserContext } from '../../hooks/UserContext';
import { FlipbookConfigData, FlipbookContext } from './Hooks/FlipbookContext';
import _, { isEmpty, isUndefined } from 'lodash';
// import {flipbook_permissions} from './permission.js';
import { Business, User } from '../../models/User.model';
import useCurrentUser from '../../hooks/useCurrentUser';
import { getCurrentUserByToken } from '../../services/user.service';
import queryString from 'query-string';
import Snackbar from '@material-ui/core/Snackbar';
import get from 'lodash/get';
import { FlipbookStateContext } from './Hooks/FlipbookStateContext';
import jwt from 'jsonwebtoken';
import { FlipbookProductsContext } from './Hooks/FlipbookProductsContext';

const FlipbookView: FC<{
  flipbookIdOrToken: any;
  isCustomerView: boolean;
  isEditMode: boolean;
  storeDetails: any;
  setCurrentStore: any;
  currentStore: any;
  hasPermission: (permission: string) => boolean;
}> = ({
  flipbookIdOrToken,
  isCustomerView,
  storeDetails,
  setCurrentStore,
  currentStore,
  hasPermission
}) => (
  <FlipbookContainer
    flipbookIdOrToken={flipbookIdOrToken}
    isCustomerView={isCustomerView}
    isEditMode={false}
    storeDetails={storeDetails}
    setCurrentStore={setCurrentStore}
    currentStore={currentStore}
    hasPermission={hasPermission}
  />
);

const FlipbookEdit: FC<{
  flipbookIdOrToken: any;
  storeDetails: any;
  setCurrentStore: any;
  currentStore: any;
  hasPermission: (permission: string) => void;
}> = ({
  flipbookIdOrToken,
  storeDetails,
  setCurrentStore,
  currentStore,
  hasPermission
}) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const handleClose = () => {
    setOpenSnackbar(false);
  };
  return (
    <div style={{ height: '100%' }}>
      <FlipbookContainer
        flipbookIdOrToken={flipbookIdOrToken}
        isCustomerView={false}
        isEditMode={true}
        storeDetails={storeDetails}
        setCurrentStore={setCurrentStore}
        currentStore={currentStore}
        hasPermission={hasPermission}
      />
      <Snackbar
        open={openSnackbar}
        onClose={handleClose}
        message={snackbarMessage}
        autoHideDuration={1500}
      />
    </div>
  );
};

export const FlipbookDemo: FC = () => {
  const { mode, flipbookIdOrToken } = useParams();
  const user = useCurrentUser();
  const [flipbookContext, setFlipbookContext] = useState<
    FlipbookConfigData | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [storeDetails, setStoreDetails] = useState([]);
  const [userInfo, setUserInfo] = useState<User>(new User());
  const [isStoreLoadedFromQuery, setIsStoreLoadedFromQuery] = useState<boolean>(
    false
  );
  const isEditMode = mode === 'edit';
  const isCustomerView = mode === 'customer-view';
  const ComponentToView = isEditMode ? FlipbookEdit : FlipbookView;
  const [currentStore, setCurrentStore] = useState<{} | undefined>();
  const userStore = _.head(_.get(user, 'tenant_stores'));
  const query: any = queryString.parse(window.location.search);
  const [business, setBusiness] = useState(new Business());
  const [selectedBusinessId, setSelectedBusinessId] = useState<
    undefined | number
  >(undefined);
  const [skus, setSkus] = useState<string[]>([]);
  const [products, setProducts] = useState<any>([]);
  const [flipbookPages, setFlipbookPages] = useState<any>([]);

  useEffect(() => {
    const { pathname, search } = window.location;
    if (isCustomerView) {
      const token = pathname.split('/')[3];
      const tokenBu = get(jwt.decode(token), 'businessUnitId', 0);
      setSelectedBusinessId(tokenBu);
    } else {
      const urlParams = new URLSearchParams(search);
      const paramsBuId = +urlParams.get('bu')! || 0;
      setSelectedBusinessId(paramsBuId);
    }
  }, [isCustomerView]);
  const permissionCheckToEnable = (permission: string) => {
    const tenant = _.get(user, 'tenant');
    const isLegacy = _.isEmpty(_.get(tenant, 'casa_product_plans'));
    const productPlans = _.get(tenant, 'casa_product_plans', []);
    const planPermissions = _.flatMap(productPlans, 'features');

    return isLegacy || _.includes(planPermissions, permission);
  };
  useEffect(() => {
    if (!isUndefined(flipbookContext)) {
      return;
    }
    if (isCustomerView) {
      flipbookV2Service
        .getFlipbookByToken(flipbookIdOrToken)
        .then((response: any) => {
          const status: string = get(response, 'status', '');
          if (status === 'success') {
            const flipbookData: FlipbookConfigData = response.data;
            flipbookData.isCustomerView = isCustomerView;
            if (isCustomerView) {
              flipbookData.flipbookToken = flipbookIdOrToken;
            }
            setFlipbookContext(flipbookData);
          }
        });
    } else {
      flipbookV2Service
        .getFlipbookById(flipbookIdOrToken)
        .then((response: any) => {
          const status: string = get(response, 'status', '');
          if (status === 'success') {
            const flipbookData: FlipbookConfigData = response.data;
            flipbookData.isCustomerView = isCustomerView;
            if (isCustomerView) {
              flipbookData.flipbookToken = flipbookIdOrToken;
            }
            setFlipbookContext(flipbookData);
          }
        });
    }
  }, [flipbookIdOrToken, flipbookContext, isCustomerView]);
  const updateFlipbook = useCallback(() => {
    setFlipbookContext(undefined);
  }, []);
  useEffect(() => {
    if (!isCustomerView && !isEmpty(user.getBusinesses())) {
      setBusiness(
        _(user.getBusinesses()).find(bu => bu.id === selectedBusinessId) ||
          new Business()
      );
      setUserInfo(user);
    } else if (isCustomerView) {
      getCurrentUserByToken(flipbookIdOrToken).then(user => {
        const brand = _.head(_.get(user, 'brands', []));
        const businesses = _.get(brand, 'businesses', []);
        setUserInfo(user);
        setBusiness(
          _(businesses).find(bu => bu.id === selectedBusinessId) ||
            new Business()
        );
      });
    }
  }, [flipbookIdOrToken, isCustomerView, user, selectedBusinessId]);

  useEffect(() => {
    if (isCustomerView && !_.isEmpty(flipbookContext)) {
      getStoresByToken(flipbookIdOrToken).then((storeDetails: any) => {
        setStoreDetails(storeDetails);
        const storeFromQueryParams = query.store || '';
        let updatedStore = _.find(storeDetails, {
          id: _.get(flipbookContext, 'tenantStoreId')
        });
        if (!_.isEmpty(storeFromQueryParams)) {
          updatedStore = _.find(storeDetails, store => {
            return (
              store.store.toLowerCase() === storeFromQueryParams.toLowerCase()
            );
          });
        }
        setCurrentStore(updatedStore);
        setIsStoreLoadedFromQuery(true);
        setIsLoading(false);
      });
    }
  }, [flipbookContext, flipbookIdOrToken, isCustomerView, query.store]);

  useEffect(() => {
    const storeId =
      _.get(flipbookContext, 'tenantStoreId') || _.get(userStore, 'id', '');
    if (_.get(business, 'id') !== 0 && !isCustomerView) {
      getStores(business).then((storeDetails: any) => {
        setStoreDetails(storeDetails);
        const updatedStore = _.find(storeDetails, {
          id: storeId
        });
        setCurrentStore(updatedStore);
        setIsLoading(false);
      });
    }
    setIsLoading(false);
  }, [business, flipbookContext, isCustomerView, userStore]);

  return (
    <BusinessUnitContext.Provider value={{ business, setBusiness }}>
      <UserContext.Provider value={{ userInfo, setUserInfo }}>
        <FlipbookContext.Provider
          value={{
            flipbookContext,
            setFlipbookContext,
            updateFlipbook
          }}
        >
          <FlipbookStateContext.Provider value={{ isStoreLoadedFromQuery }}>
            <FlipbookProductsContext.Provider
              value={{
                skus: skus,
                setSkus: setSkus,
                products: products,
                setProducts: setProducts,
                flipbookPages: flipbookPages,
                setFlipbookPages: setFlipbookPages
              }}
            >
              <div className={'flipbook-main-container'}>
                {isLoading && (
                  <div className={'flipbook-loader'}>
                    <CircularProgress
                      color="inherit"
                      variant="indeterminate"
                      size={60}
                      thickness={5}
                    />
                  </div>
                )}
                {!isLoading && !isEmpty(flipbookContext) && (
                  <ComponentToView
                    flipbookIdOrToken={flipbookIdOrToken}
                    isCustomerView={isCustomerView}
                    isEditMode={isEditMode}
                    storeDetails={storeDetails}
                    setCurrentStore={setCurrentStore}
                    currentStore={currentStore}
                    hasPermission={permissionCheckToEnable}
                  />
                )}
              </div>
            </FlipbookProductsContext.Provider>
          </FlipbookStateContext.Provider>
        </FlipbookContext.Provider>
      </UserContext.Provider>
    </BusinessUnitContext.Provider>
  );
};

export default FlipbookDemo;
