/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import useCurrentUser from '../../hooks/useCurrentUser';
import _, { isEmpty } from 'lodash';
import offerTagService from '../../services/offerTags.service';
import { CircularProgress, TextField } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import { Offer, OfferMetaField } from '../../models/offer/Offer.model';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import Switch from '@material-ui/core/Switch';
import { ImageUpload } from './ImageUpload';
import clsx from 'clsx';
import { ReadOnlyContext } from './ReadOnlyContext';
import offerService from '../../services/offer.service';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import CustomCheckbox from '../OfferDefinition/CheckBox';
import { styled } from '@material-ui/styles';
import durationDarkIcon from '../Assets/Offers/durationDark.svg';
import { InputAdornment } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import offerCategoryService from '../../services/offerCategory.service';
import { toast } from 'react-toastify';

const StyledAppSelect = styled(Select)({
  '& .MuiOutlinedInput-input': {
    padding: '5px 10px',
    paddingRight: 32
  }
});

const useStyles = makeStyles(theme => ({
  required: {
    '&:after': {
      content: `' *'`,
      color: theme.palette.primary.main
    }
  },
  container: {
    display: 'grid',
    gap: '50px',
    gridTemplateColumns: '2fr 2fr 1fr',
    padding: 20
  },
  addImageBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
    border: '1px solid #DDDDDD',
    boxShadow: '0px 2px 5px #0000001A',
    margin: '20px auto',
    cursor: 'pointer'
  },
  addImageMainLabel: {
    color: theme.palette.primary.main
  },
  addImageHintLabel: {
    fontSize: '14px',
    color: '#707070'
  },
  fieldLabel: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  fieldContainer: {
    marginTop: 20,
    marginBottom: 20
  },
  selectBar: {
    background: '#FFF',
    minHeight: 50,
    paddingTop: 0,
    paddingBottom: 0
  },
  selectBody: {
    '&:focus': {
      background: 'none'
    }
  },
  selectTag: {
    minWidth: '50%'
  },
  metaField: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridColumnGap: 25,
    margin: '20px auto'
  },
  clearMetaField: {
    display: 'flex',
    alignItems: 'end'
  },
  mainButton: {
    height: 40,
    width: 150
  },
  autoGenerateButtonStyle: {
    backgroundColor: '#f3755f !important',
    color: '#fff',
    width: '200px',
    marginLeft: 20
  },

  stageDesc: {
    textAlign: 'center',
    font: '12px/16px Nunito',
    letterSpacing: '0',
    color: '#707070',
    opacity: 1,
    margin: 0
  },
  offerDescriptionSection: {
    marginTop: '10px',
    background: '#f5f5f5 0 0 no-repeat padding-box',
    borderRadius: '8px',
    opacity: 1
  },
  bottomSectionHeaders: {
    textAlign: 'left',
    font: 'Bold 16px Nunito',
    letterSpacing: '0',
    color: '#404040',
    opacity: 1,
    paddingTop: '5px',
    paddingLeft: '10px'
  },
  bottomSectionDesc: {
    textAlign: 'left',
    font: '13px/18px Nunito',
    letterSpacing: 0,
    color: '#767676',
    opacity: 1,
    margin: '10px 10px 10px 30px'
  }
}));

const ValidationStatus = {
  Fresh: 'Fresh',
  Validating: 'Validating',
  ValidatedSuccess: 'ValidatedSuccess',
  ValidatedCodeExistsError: 'ValidatedCodeExistsError',
  ValidatedMiscError: 'ValidatedMiscError'
};

const validationIcon = {
  [ValidationStatus.Fresh]: '',
  [ValidationStatus.Validating]: <CircularProgress size={24} />,
  [ValidationStatus.ValidatedSuccess]: <CheckIcon color={'primary'} />,
  [ValidationStatus.ValidatedMiscError]: (
    <Tooltip title={'Code could not be validated'}>
      <ErrorIcon color={'error'} />
    </Tooltip>
  ),
  [ValidationStatus.ValidatedCodeExistsError]: (
    <Tooltip title={'Code Already Exists'}>
      <ErrorIcon color={'error'} />
    </Tooltip>
  )
};

export const OfferDescriptionStage = props => {
  const user = useCurrentUser();
  const { readOnly } = useContext(ReadOnlyContext);
  const [availableTags, setAvailableTags] = useState([]);
  const [offerCodeValidationStatus, setOfferCodeValidationStatus] = useState(
    ValidationStatus.Fresh
  );
  const [availableOfferCategories, setAvailableOfferCategories] = useState([]);
  const tenantId = useMemo(() => user.tenant.id, [user]);

  const {
    offer,
    changeOfferField,
    onClickNext,
    selectedOfferCategory,
    onSelectOfferCategory
  } = props;
  const {
    tenantBrandBusinessId,
    name,
    code,
    categoryId,
    description,
    tags,
    isCustomerSpecific,
    metaFields,
    sortOrder,
    redemptionLimit,
    redemptionLimitPerCustomer,
    remarks = ''
  } = offer;
  const { validity, startDate, endDate } = offer;
  const [offerValidity, setOfferValidity] = useState(validity);
  const [offerValidityCheckbox, setOfferValidityCheckbox] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (!startDate)
      changeOfferField(
        'startDate',
        moment()
          .startOf('day')
          .toString()
      );
    if (!endDate)
      changeOfferField(
        'endDate',
        moment()
          .endOf('day')
          .toString()
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // assign first business unit's id if offer does not have a business unit id
  useEffect(() => {
    if (_.isNumber(tenantBrandBusinessId) && tenantBrandBusinessId !== 0)
      return;

    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );

    if (paramsBusiness) {
      changeOfferField('tenantBrandBusinessId', paramsBusiness.id);
    }
  }, [changeOfferField, tenantBrandBusinessId, user]);

  useEffect(() => {
    if (isNaN(tenantBrandBusinessId) || tenantBrandBusinessId === 0) return;
    offerTagService.getOfferTags(tenantBrandBusinessId).then(offerTags => {
      setAvailableTags(offerTags);
    });
  }, [tenantBrandBusinessId]);

  useEffect(() => {
    if (!tenantId || isNaN(tenantId) || tenantId === 0) return;
    offerCategoryService.getOfferCategories().then(offerCategories => {
      setAvailableOfferCategories(offerCategories);
      onSelectOfferCategory(
        offerCategories.find(({ id }) => categoryId === id) || ''
      );
    });
  }, [tenantId]);

  useEffect(() => {
    changeOfferField('categoryId', selectedOfferCategory?.id || null);
  }, [selectedOfferCategory]);

  const validateOfferExists = useCallback(
    _.debounce(
      async code => {
        if (!code) {
          setOfferCodeValidationStatus(ValidationStatus.Fresh);
          return;
        }
        setOfferCodeValidationStatus(ValidationStatus.Validating);
        let validationStatus;
        try {
          const offerExists = await offerService.getOfferExists(code);
          validationStatus = offerExists
            ? ValidationStatus.ValidatedCodeExistsError
            : code.length > 2
            ? ValidationStatus.ValidatedSuccess
            : ValidationStatus.ValidatedCodeExistsError;
        } catch (e) {
          console.error(e);
          validationStatus = ValidationStatus.ValidatedMiscError;
        } finally {
          setOfferCodeValidationStatus(validationStatus);
        }
      },
      500,
      { leading: true }
    ),
    []
  );

  useEffect(() => {
    validateOfferExists(code).then(_.noop);
  }, [code, validateOfferExists]);

  const onTextFieldChange = event => {
    changeOfferField(event.target.name, event.target.value);
  };

  const genericField = (label, name, value) => {
    return (
      <div className="ms-3 flex-grow-1 d-flex flex-column justify-content-between">
        <div className={clsx(classes.fieldLabel, classes.required)}>
          {label}
        </div>
        <TextField
          name={name}
          value={value}
          fullWidth
          onChange={onTextFieldChange}
          placeholder={0}
          disabled={readOnly}
          type="number"
          onKeyDown={e =>
            ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
          }
        />
      </div>
    );
  };

  const onSwitchFieldChange = event => {
    changeOfferField(event.target.name, event.target.checked);
  };

  const setTags = newTags => {
    changeOfferField('tags', newTags);
  };

  const onOfferCodeChange = event => {
    // remove all characters except letters, nums, underscore and hyphen
    // and convert the result to uppercase
    const newCode = event.target.value
      .replace(/[^a-z0-9_-]/gi, '')
      .toUpperCase();
    changeOfferField('code', newCode);
  };

  const onCreateTagClick = async tagName => {
    try {
      await offerTagService.createOfferTag(tenantBrandBusinessId, tagName);
      const offerTags = await offerTagService.getOfferTags(
        tenantBrandBusinessId
      );
      setAvailableTags(offerTags);
      const newTag = _.find(offerTags, o => o.name === tagName);
      setTags([...tags, newTag]);
    } catch (e) {
      // TODO: give a toast on error
      console.error(e);
    }
  };

  const onCreateOfferCategory = async offerCategory => {
    try {
      const { id, category } = await offerCategoryService.createOfferCategory(
        offerCategory
      );

      setAvailableOfferCategories(availableOfferCategories => [
        { id, category },
        ...availableOfferCategories
      ]);
      onSelectOfferCategory({ id, category });
    } catch (e) {
      console.error(e);
    }
  };

  const handleTagSelect = async (event, newValue) => {
    const uncreatedTag = _.find(newValue, o => !_.isEmpty(o.inputValue));
    if (!_.isUndefined(uncreatedTag)) {
      const tagName = uncreatedTag.inputValue;
      await onCreateTagClick(tagName, event);
    } else {
      setTags(newValue);
    }
  };

  const handleOfferCategorySelect = async (event, newValue) => {
    const offerCategory = _.get(newValue, 'inputValue');

    if (!_.isUndefined(offerCategory)) {
      await onCreateOfferCategory(offerCategory);
    } else {
      onSelectOfferCategory(newValue);
    }
  };

  const handleMetaFieldAdd = () => {
    changeOfferField('metaFields', [...metaFields, new OfferMetaField()]);
  };

  const handleMetaFieldChange = (index, field, value) => {
    const newMetaFields = [
      ...metaFields.slice(0, index),
      new OfferMetaField(field, value),
      ...metaFields.slice(index + 1)
    ];
    changeOfferField('metaFields', newMetaFields);
  };
  const handleMetaFieldDelete = index => {
    const newMetaFields = [
      ...metaFields.slice(0, index),
      ...metaFields.slice(index + 1)
    ];
    changeOfferField('metaFields', newMetaFields);
  };

  const filter = createFilterOptions();

  const handleAutoGenerate = () => {
    // const data = _.find(user.getBusinesses(), function(item) {
    //   return item.id === tenantBrandBusinessId;
    // });
    const autoCode = `${moment().format('YYMMDDHHmmss')}`;

    // const autoCode = `${data.name
    //   .slice(0, 3)
    //   .toUpperCase()} - ${moment().valueOf()}`;
    changeOfferField('code', autoCode.toUpperCase());
  };

  const handleValidity = e => {
    if (!e.target.checked) {
      setOfferValidity({ ...validity, unit: 'days', count: '' });
      setOfferValidityCheckbox(false);
    } else {
      setOfferValidityCheckbox(true);
    }
  };

  const handleCountValue = (e, name) => {
    let countVal = e.target.value;
    countVal = countVal.replace('.', '');
    countVal = countVal.replace('e', '');
    countVal = countVal.replace('E', '');
    countVal = countVal.replace('-', '');
    setOfferValidity({ ...validity, [name]: countVal });
    if (name === 'count') {
      setOfferValidityCheckbox(true);
    }
  };

  useEffect(() => {
    changeOfferField('validity', offerValidity);
  }, [offerValidity]);

  const handleNext = () => {
    const isNameEmpty = isEmpty(name) || isEmpty(name.trim());
    const isDescriptionEmpty =
      isEmpty(description) || isEmpty(description.trim());
    const isCodeEmpty = isEmpty(code) || isEmpty(code.trim());
    const isPriorityEmpty = sortOrder !== 0 && isEmpty(sortOrder);

    if (isNameEmpty || isDescriptionEmpty || isCodeEmpty || isPriorityEmpty) {
      toast.error('Mandatory fields cannot be empty');
      return;
    }

    onClickNext();
  };

  return (
    <div className={classes.container}>
      <div>
        <div>
          <div className={clsx(classes.fieldLabel)}>Upload Thumbnail Image</div>
          <ImageUpload
            onRemove={() => {
              changeOfferField('imageUrl', undefined);
            }}
            imageUrl={offer.imageUrl}
            onUpload={newImageUrl => {
              changeOfferField('imageUrl', newImageUrl);
            }}
            hintLabel={'Image size 150px X 150px'}
            mainLabel={'Upload Thumbnail Image'}
            readOnly={readOnly}
          />
        </div>
        <div>
          <div className={classes.fieldLabel}>Upload Banner Image</div>
          <ImageUpload
            onRemove={() => {
              changeOfferField('bannerImageUrl', undefined);
            }}
            imageUrl={offer.bannerImageUrl}
            onUpload={newImageUrl => {
              changeOfferField('bannerImageUrl', newImageUrl);
            }}
            hintLabel={'Image size 360px X 150px'}
            mainLabel={'Upload Banner Image'}
            readOnly={readOnly}
          />
        </div>
      </div>
      <div>
        <div className={classes.fieldContainer}>
          <div className={clsx(classes.fieldLabel, classes.required)}>
            Offer Code
          </div>
          <div style={{ display: 'flex' }}>
            <TextField
              name={'code'}
              value={code || ''}
              fullWidth
              onChange={onOfferCodeChange}
              placeholder={'DIWALI30'}
              disabled={readOnly || offer.status === 1}
              InputProps={{
                endAdornment:
                  !readOnly &&
                  (!offer.status || offer.status === 0) &&
                  validationIcon[offerCodeValidationStatus]
              }}
            />
            {!readOnly && (!offer.status || offer.status === 0) && (
              <Button
                type="button"
                onClick={handleAutoGenerate}
                className={classes.autoGenerateButtonStyle}
                disabled={readOnly || offer.status === 1}
              >
                Auto-Generate
              </Button>
            )}
          </div>
        </div>

        <div className={classes.fieldContainer}>
          <div className={clsx(classes.fieldLabel, classes.required)}>
            Offer Name
          </div>
          <TextField
            name={'name'}
            value={name || ''}
            fullWidth
            onChange={onTextFieldChange}
            placeholder={'Diwali 30%'}
            disabled={readOnly}
          />
        </div>
        <div className={classes.fieldContainer}>
          <div className={clsx(classes.fieldLabel, classes.required)}>
            Offer Description
          </div>
          <TextField
            name={'description'}
            value={description || ''}
            fullWidth
            onChange={onTextFieldChange}
            placeholder={'Get 30% off on all items during Diwali'}
            disabled={readOnly}
          />
        </div>
        <div className={classes.fieldContainer}>
          <div className={clsx(classes.fieldLabel)}>Remarks</div>
          <TextField
            name={'remarks'}
            value={remarks || ''}
            fullWidth
            onChange={onTextFieldChange}
            placeholder={'Enter your remarks'}
            disabled={readOnly}
          />
        </div>
        <div className={classes.fieldContainer}>
          <div className={clsx(classes.fieldLabel, 'mb-3')}>
            PWA Offer Display Name
          </div>
          <FormControl className={classes.selectTag + ' w-100'}>
            <Autocomplete
              classes={{
                root: classes.root,
                inputRoot: classes.selectBar
              }}
              size={'small'}
              renderInput={params => (
                <TextField
                  {...params}
                  variant={'outlined'}
                  placeholder={'DAMAKA DIWALI'}
                  fullWidth
                />
              )}
              options={availableOfferCategories}
              getOptionLabel={option => {
                return (option.category || '').replace(' Create ', 'Creating ');
              }}
              getOptionSelected={(option, value) => option.id === value}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const filteredTagNames = _.map(filtered, t => t.category);
                const inputValue = params.inputValue;
                if (
                  inputValue !== '' &&
                  !_.includes(filteredTagNames, inputValue)
                )
                  return [
                    { inputValue, category: ` Create '${inputValue}'` },
                    ...filtered
                  ];
                return filtered;
              }}
              renderOption={option => option.category}
              value={selectedOfferCategory}
              onChange={handleOfferCategorySelect}
              disabled={readOnly}
            />
          </FormControl>
        </div>
        <div className={classes.fieldContainer + ' d-flex align-items-stretch'}>
          <div className="me-3 flex-grow-1 d-flex flex-column justify-content-between">
            <div className={classes.fieldLabel}>Select Tags</div>
            <FormControl className={classes.selectTag + ' w-100'}>
              <Autocomplete
                classes={{
                  root: classes.root,
                  inputRoot: classes.selectBar
                }}
                multiple
                size={'small'}
                disableCloseOnSelect
                renderInput={params => (
                  <TextField
                    {...params}
                    variant={'outlined'}
                    placeholder={'Add tags'}
                    fullWidth
                  />
                )}
                filterSelectedOptions
                options={availableTags}
                getOptionLabel={option => option.name || ''}
                getOptionSelected={(option, value) => option.id === value.id}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const filteredTagNames = _.map(filtered, t => t.name);
                  const inputValue = params.inputValue;
                  if (
                    inputValue !== '' &&
                    !_.includes(filteredTagNames, inputValue)
                  )
                    return [
                      { inputValue, name: `Create "${inputValue}"` },
                      ...filtered
                    ];
                  return filtered;
                }}
                renderOption={option => option.name}
                value={tags}
                onChange={handleTagSelect}
                disabled={readOnly}
              />
            </FormControl>
          </div>
          {genericField('Priority', 'sortOrder', sortOrder)}
        </div>
        <div className={classes.fieldContainer}>
          <div className={classes.fieldLabel}>Is Customer Specific</div>
          <Switch
            color={'primary'}
            name={'isCustomerSpecific'}
            checked={isCustomerSpecific}
            onChange={onSwitchFieldChange}
            placeholder={'Get 30% off on all items during Diwali'}
            disabled={readOnly}
          />
        </div>
        {isCustomerSpecific && (
          <>
            <div
              className={classes.fieldContainer + ' d-flex align-items-stretch'}
            >
              {genericField(
                'Redemption Limit',
                'redemptionLimit',
                redemptionLimit
              )}
              {genericField(
                'User Redemption Limit',
                'redemptionLimitPerCustomer',
                redemptionLimitPerCustomer
              )}
            </div>
            <div style={{ marginBottom: 20 }}>
              <CustomCheckbox
                name="offerValidityCheckbox"
                defaultChecked={validity?.count || ''}
                disabled={readOnly}
                onChange={event => handleValidity(event)}
                value={offerValidityCheckbox}
              />
              <span style={{ fontWeight: 700 }}>
                Event based Offer Validity
              </span>
              <div style={{ marginLeft: 43 }}>
                <span>Offer redeemable for </span>

                <TextField
                  name="count"
                  type="number"
                  pattern="[0-9]*"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={validity?.count}
                  onChange={e => handleCountValue(e, 'count')}
                  disabled={readOnly}
                  style={{ width: 80, marginLeft: 10 }}
                />
                <StyledAppSelect
                  variant="outlined"
                  style={{ margin: '0 10px' }}
                  name="unit"
                  onChange={e => handleCountValue(e, 'unit')}
                  value={validity?.unit}
                  disabled={readOnly}
                >
                  <MenuItem value={'months'}>Months</MenuItem>
                  <MenuItem value={'days'}>Days</MenuItem>
                </StyledAppSelect>
                <span>from the Trigger date </span>
              </div>
            </div>
          </>
        )}
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={durationDarkIcon} alt={'duration'} />
                <span className={classes.bottomSectionHeaders}>
                  Duration of offer:
                </span>
              </div>
              <div className={classes.bottomSectionDesc}>
                Offer is valid only for the following duration:
              </div>
              <div style={{ display: 'flex', marginLeft: 30, gap: 20 }}>
                <div>
                  <div className={clsx(classes.fieldLabel, classes.required)}>
                    Start Date
                  </div>
                  <DateTimePicker
                    style={{ width: '250px' }}
                    autoOk
                    disablePast={!readOnly}
                    format={'MMM do yyyy hh:mm a'}
                    variant={'inline'}
                    value={startDate}
                    onChange={date => changeOfferField('startDate', date)}
                    inputVariant={'outlined'}
                    disabled={readOnly}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={'start'}>
                          <DateRangeOutlinedIcon color={'primary'} />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div>
                  <div className={clsx(classes.fieldLabel, classes.required)}>
                    End Date
                  </div>
                  <DateTimePicker
                    autoOk
                    style={{ width: '250px' }}
                    disablePast={!readOnly}
                    format={'MMM do yyyy hh:mm a'}
                    variant={'inline'}
                    value={endDate}
                    onChange={date => changeOfferField('endDate', date)}
                    inputVariant={'outlined'}
                    disabled={readOnly}
                    minDate={startDate}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={'start'}>
                          <DateRangeOutlinedIcon color={'primary'} />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.fieldContainer}>
          <div className={classes.fieldLabel}>Meta Fields</div>
          {!readOnly && (
            <div>
              <span
                style={{ color: '#EB7461', cursor: 'pointer' }}
                onClick={handleMetaFieldAdd}
              >
                Add Meta Field
              </span>
              <span
                style={{
                  color: '#888888',
                  fontSize: '14px',
                  marginLeft: '5px'
                }}
              >
                (Add More fields you need and set keys and values)
              </span>
            </div>
          )}
          {readOnly && _.isEmpty(metaFields) && (
            <div style={{ fontStyle: 'italic' }}>None</div>
          )}
          {_.map(metaFields, (metaField, index) => {
            return (
              <div key={index} className={classes.metaField}>
                <TextField
                  placeholder={'Enter Key'}
                  value={metaField.field}
                  disabled={readOnly}
                  onChange={e => {
                    const newField = e.target.value;
                    handleMetaFieldChange(index, newField, metaField.value);
                  }}
                />
                <TextField
                  placeholder={'Enter Value'}
                  value={metaField.value}
                  disabled={readOnly}
                  onChange={e => {
                    const newValue = e.target.value;
                    handleMetaFieldChange(index, metaField.field, newValue);
                  }}
                />
                {!readOnly && (
                  <div className={classes.clearMetaField}>
                    <ClearIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleMetaFieldDelete(index)}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '30px',
            marginTop: 50
          }}
        >
          <Button
            className={classes.mainButton}
            variant={'contained'}
            color={'primary'}
            onClick={handleNext}
            disableElevation
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

OfferDescriptionStage.propTypes = {
  offer: PropTypes.instanceOf(Offer).isRequired,
  changeOfferField: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired
};
