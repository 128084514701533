import React, { Context } from 'react';
import noop from 'lodash/noop';

type FlipbookCartContextType = {
  cartItems: [];
  storeCode: string;
  setCartItems: (cartItems: any) => void;
  removedItems: any;
  setRemovedItems: (items: any) => void;
  isInvalidCart: boolean;
  setIsInvalidCart: (flag: boolean) => void;
  isSessionActivated: boolean;
  setIsSessionActivated: (flag: boolean) => void;
};
export const FlipbookCartContext: Context<FlipbookCartContextType> = React.createContext<
  FlipbookCartContextType
>({
  cartItems: [],
  storeCode: '',
  setCartItems: noop,
  isSessionActivated: false,
  removedItems: [],
  setRemovedItems: noop,
  isInvalidCart: false,
  setIsInvalidCart: noop,
  setIsSessionActivated: noop
});
