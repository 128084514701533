/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Box
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import KPIReportDownload from '../../../models/TenantReports/KPIReportDownload';
import TableBodyLoader from '../../../shared/components/TableBodyLoader/TableBodyLoader';
import TableFooter from '../../../shared/components/TableFooter/TableFooter';
import TableHeader from '../../../shared/components/TableHeader/TableHeader';
import formatDate from '../../../shared/utils/formatDate';
import Chip from '../../../shared/components/Chip/Chip';
import { GetApp, ShowChart } from '@material-ui/icons';
import config from '../../../config';
import { isNull } from 'lodash';
import LinearProgressWithLabel from '../Shared/LinearProgressWithLabel';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts'; // Import ApexOptions from the appropriate package

interface Dataset {
  label: string;
  data: number[];
}

interface ChartData {
  title: string;
  labels: string[];
  datasets: Dataset[];
}

interface KPIReportDownloadTableProps {
  reports: KPIReportDownload[];
  isLoading: boolean;
  page: number;
  offset: number;
  setPage: (page: number) => void;
  setOffset: (offset: number) => void;
}

const headings = [
  'S.No',
  'Date Triggered',
  'Chosen Date',
  'Progress',
  'Status',
  'Download',
  'Charts'
];

const KPIReportDownloadTable: FC<KPIReportDownloadTableProps> = props => {
  const { reports, isLoading, page, offset, setPage, setOffset } = props;
  const [selectedChartData, setSelectedChartData] = useState<ChartData[]>([]);

  const getDownloadReportUrl = (url: string): string => {
    if (isNull(url)) {
      return '';
    }
    const indexOfReportUrl = url.indexOf('/report') + 1;
    return `${config.ruleServerUrl}/download-file/${url.slice(
        indexOfReportUrl
    )}`;
  };

  const handleChartClick = (chartData: ChartData[]) => {
    console.log('Chart data clicked:', chartData);
    setSelectedChartData(chartData);
  };

  const chartOptions = (chartData: ChartData): ApexOptions => ({
    chart: {
      type: 'line',
      height: 350,
      zoom: { enabled: false }
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth' },
    title: { text: chartData.title, align: 'left' },
    xaxis: { categories: chartData.labels },
    series: chartData.datasets.map(dataset => ({
      name: dataset.label,
      data: dataset.data
    }))
  });

  return (
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHeader headings={headings} align="center" />
          {isLoading ? (
              <TableBodyLoader colSpan={headings.length} rowsPerPage={offset} />
          ) : (
              <TableBody>
                {reports.map((report, index) => (
                    <TableRow key={report.id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {formatDate(report.createdAt)}
                      </TableCell>
                      <TableCell align="center">
                        {formatDate(report.data.reportFilterDate)}
                      </TableCell>
                      <TableCell align="center">
                        <LinearProgressWithLabel
                            total={report.total}
                            currentValue={report.processedRecords}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Chip
                            label={report.status}
                            color={
                              report.status === 'COMPLETED'
                                  ? 'success'
                                  : report.status === 'FAILED'
                                      ? 'error'
                                      : 'primary-light'
                            }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                            disabled={report.status !== 'COMPLETED'}
                            component="a"
                            href={getDownloadReportUrl(report.errorFileLocation)}
                            download
                        >
                          <GetApp />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                            disabled={report.status !== 'COMPLETED'}
                            onClick={() => handleChartClick(report.data.results)}
                        >
                          <ShowChart />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
          )}
          <TableFooter
              colSpan={headings.length}
              count={-1}
              page={page}
              rowsPerPage={offset}
              onPageChange={setPage}
              onRowPerPageChange={setOffset}
          />
        </Table>

        {selectedChartData.length > 0 && (
            <Box mt={4}>
              <Typography variant="h6" align="center">
                Charts
              </Typography>
              {selectedChartData.map((chartData, index) => (
                  <div key={index}>
                    <Chart
                        options={chartOptions(chartData)}
                        series={chartOptions(chartData).series}
                        type="line"
                        height={350}
                    />
                  </div>
              ))}
            </Box>
        )}
      </TableContainer>
  );
};

export default KPIReportDownloadTable;
