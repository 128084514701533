import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Badge } from '@material-ui/core';
import _, { find, map, set, values, without } from 'lodash';
import cartIcon from '../../Assets/images/cart-icon.svg';
import summaryIcon from '../../Assets/summary.svg';
import removeIcon from '../../Assets/images/remove_img_icon.svg';
import SelectWithNumbers from '../SelectWithNumbers/SelectWithNumbers';
import {
  getCartItemsAsText,
  getTotalCartAmount,
  getValidMobileNumber,
  isEcomUserLoggedIn,
  updateFlipbookCart
} from '../Utiles/FlipbookUtiles';
import {
  clearCart,
  removeCartLineItem,
  updateLineItemQty
} from '../../../services/ecomCart.service';
import './MyCart.css';
import Button from '@material-ui/core/Button';
import casaProductMaster from '../../../services/productMaster.service';
import ChatIcon from '@material-ui/icons/Chat';
import { AVAILABLE_CHECKOUTS } from '../Utiles/FlipbookConfig';
import { FlipbookCheckoutPopup } from './FlipbookCheckoutPopup';
import { FlipbookCartContext } from '../Hooks/FlipbookCartContext';
import { FlipbookContext } from '../Hooks/FlipbookContext';
import { CheckoutOption } from '../../../models/FlipbookV2/flipbookV2.model';
import { Cart } from '../CheckoutForm/InvalidCart';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';

const defaultCheckoutFormSteps = ['Login', 'Address', 'Payment'];
const whatsAppCheckoutFormSteps = ['Login'];

interface MyCartProps {
  storeDetails: any;
  flipbookIdOrToken: any;
  currentStore: any;
  cartType?: 'ValidCart' | 'InvalidCart';
  invalidCartCTA?: () => void;
  cartResponse?: Cart[];
}
export const MyCart: FC<MyCartProps> = ({
  storeDetails,
  flipbookIdOrToken,
  currentStore,
  cartType = 'ValidCart',
  cartResponse,
  invalidCartCTA = () => {
    return [];
  }
}) => {
  const flipbookCartContext = useContext(FlipbookCartContext);
  const flipbookContext = useContext(FlipbookContext);
  const cartItems: any = flipbookCartContext.cartItems;
  const setCartItems = flipbookCartContext.setCartItems;
  const isCartContainsInvalidLineItems = flipbookCartContext.isInvalidCart;
  const setIsCartContainsInvalidLI = flipbookCartContext.setIsInvalidCart;
  const checkoutOption =
    flipbookContext.flipbookContext?.checkoutOption ||
    CheckoutOption.FlipbookCheckout;
  const removedCartItems = flipbookCartContext.removedItems;
  const setRemovedCartItems = flipbookCartContext.setRemovedItems;
  const [isLineItemValidated, setIsLineItemValidated] = useState(false);
  const [name, setName] = useState('');
  const [isCheckoutFormOpen, setIsCheckoutFormOpen] = useState(false);
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const [allProducts, setAllProducts] = useState<any>();
  const [isCustomerAuthenticated, setIsCustomerAuthenticated] = useState(
    isEcomUserLoggedIn()
  );

  useEffect(() => {
    _.isEmpty(cartItems) ? setIsCartEmpty(true) : setIsCartEmpty(false);
  }, [cartItems]);

  const findLineItemForSku = useCallback(
    (sku: string) => {
      return find(cartResponse, lineItem => lineItem?.sku === sku);
    },
    [cartResponse]
  );
  const isInvalidCart = useCallback(() => {
    return cartType === 'InvalidCart';
  }, [cartType]);
  const isQuantityNotAvailable = useCallback(
    (item: any): boolean => {
      if (!isInvalidCart()) {
        return false;
      }
      const responseLineItem = findLineItemForSku(item.sku);
      const availableStock = get(responseLineItem, 'availableStock', 0);
      return availableStock <= 0;
    },
    [findLineItemForSku, isInvalidCart]
  );
  const removeCartItem = useCallback(
    (lineItemId: number) => {
      removeCartLineItem(lineItemId).then(
        res => {
          if (!_.has(res, 'error')) {
            setCartItems(updateFlipbookCart(res));
          }
        },
        err => err
      );
    },
    [setCartItems]
  );
  const updateAndProceedCartCTA = () => {
    if (isEmpty(cartItems)) {
      clearCart();
    }
    map(removedCartItems, item => {
      removeCartItem(item.lineItemId);
    });
    resetInvalidCart();
    invalidCartCTA();
  };
  useEffect(() => {
    if (isEmpty(cartItems) || isLineItemValidated || !isInvalidCart()) {
      return;
    }

    const validLineItems = map(cartItems, item => {
      if (isQuantityNotAvailable(item)) {
        set(removedCartItems, item.sku, {
          quantity: item.quantity,
          sku: item.sku,
          lineItemId: item.lineItemId,
          isRemovedItem: true
        });
        setRemovedCartItems(removedCartItems);
        return undefined;
      }
      const responseLineItemSKU = findLineItemForSku(item?.sku);
      return {
        ...item,
        quantity: responseLineItemSKU?.availableStock || item.quantity
      };
    });
    setIsLineItemValidated(true);
    setCartItems(without(validLineItems, undefined));
  }, [
    cartItems,
    findLineItemForSku,
    isInvalidCart,
    isLineItemValidated,
    isQuantityNotAvailable,
    removeCartItem,
    removedCartItems,
    setCartItems,
    setRemovedCartItems
  ]);
  useEffect(() => {
    const filteredSkus = _.map(cartItems, 'sku');
    casaProductMaster
      .listProductsBySkuAndToken(flipbookIdOrToken, filteredSkus)
      .then(products => {
        setAllProducts(products);
      });
  }, [cartItems, flipbookIdOrToken]);

  const getProductBySku = (sku: string) => {
    return _.find(allProducts, p => p.extendedData.sku === sku);
  };

  const updateCartItemQty = (lineItemId: number, qty: number) => {
    const lineItemInfo = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      line_item_id: lineItemId,
      quantity: qty
    };
    updateLineItemQty(lineItemInfo).then(
      res => {
        if (!_.has(res, 'error')) {
          setCartItems(updateFlipbookCart(res));
        } else if (res.error === 'Stock is less than requested quantity') {
          toast.error('Stock not available');
          res.status === 'restored' &&
            setTimeout(() => {
              window.location.reload();
            }, 3000);
        }
      },
      err => err
    );
  };

  const onClickBuyNow = () => {
    setIsCheckoutFormOpen(true);
  };

  const handleProductPopUpClose = () => {
    if (isCartContainsInvalidLineItems) {
      return;
    }
    setIsCheckoutFormOpen(false);
  };

  const validMobileNumber = getValidMobileNumber(currentStore);
  const whatsAppText = getCartItemsAsText(cartItems, allProducts, 'cart', name);
  const whatsAppUrl = `http://wa.me/${validMobileNumber}?text=${whatsAppText}`;

  const onClickChatAndBuy = () => {
    if (!isCustomerAuthenticated) {
      setIsCheckoutFormOpen(true);
      return;
    }
    window.open(whatsAppUrl, '_blank');
  };

  const CheckoutFormSteps = () => {
    return checkoutOption === AVAILABLE_CHECKOUTS.Store.key
      ? whatsAppCheckoutFormSteps
      : defaultCheckoutFormSteps;
  };

  const resetInvalidCart = () => {
    setRemovedCartItems([]);
    setIsCartContainsInvalidLI(false);
  };
  const getInvalidCartFooterSection = () => {
    return (
      <div className={'invalid-cart-cta-footer'}>
        <div className={'invalid-cart-clear-cart m-0-1'}>
          <Button
            variant="contained"
            style={{ fontSize: 12 }}
            onClick={() => {
              resetInvalidCart();
              clearCart();
              handleProductPopUpClose();
            }}
            className={''}
          >
            clear cart
          </Button>
        </div>
        <div className={'invalid-cart-update-cart m-0-1'}>
          <Button
            variant="contained"
            color="primary"
            disabled={isEmpty(cartItems)}
            style={{ fontSize: 12 }}
            onClick={() => updateAndProceedCartCTA()}
            className={'buy-now-btn'}
          >
            update & proceed
          </Button>
        </div>
      </div>
    );
  };
  const getValidCartFooterSection = () => {
    return (
      <div>
        {checkoutOption !== AVAILABLE_CHECKOUTS.Store.key && (
          <Button
            variant="contained"
            color="primary"
            disabled={isCartEmpty}
            style={{ fontSize: 12 }}
            onClick={() => onClickBuyNow()}
            className={'buy-now-btn'}
          >
            Buy Now
          </Button>
        )}
        {checkoutOption === AVAILABLE_CHECKOUTS.Store.key && (
          <div>
            <Button
              variant="contained"
              color="primary"
              className={'buy-now-btn'}
              disabled={isCartEmpty}
              startIcon={<ChatIcon />}
              onClick={() => onClickChatAndBuy()}
            >
              {'Send Reservation to Store'}
            </Button>
          </div>
        )}
      </div>
    );
  };
  const getFooter = () => {
    return (
      <div className={'buy-now'}>
        {isInvalidCart()
          ? getInvalidCartFooterSection()
          : getValidCartFooterSection()}
      </div>
    );
  };

  // Checks whether line item contains stock
  const isLineItemValid = (sku: string) => {
    const responseLineItem = findLineItemForSku(sku);
    return responseLineItem?.isAvailable;
  };

  const getInvalidResponseLineItemDecorator = (item: any) => {
    if (isLineItemValid(item.sku)) {
      return 'valid-line-item';
    }
    return isQuantityNotAvailable(item)
      ? 'out-of-stock-line-item'
      : 'invalid-line-item';
  };

  const getCartItems = (items: any) => {
    return _.map(items, (item, i) => {
      const product = getProductBySku(item.sku);
      const isRemovedItem = get(item, 'isRemovedItem', false);
      return (
        <div className={`${isRemovedItem ? 'removed-line-item' : ''}`}>
          {isRemovedItem && (
            <div className={'sold-out-line-item'}>
              <span className={'sold-out-line-item-text'}>sold out</span>
            </div>
          )}
          <div
            key={i}
            className={`my-4 mx-1 cart-listing-container p-1 ${
              isInvalidCart() ? getInvalidResponseLineItemDecorator(item) : ''
            } ${isRemovedItem ? 'o-50' : ''}`}
          >
            <div className="cart-item-img">
              <img
                className={'cart-listing-img'}
                src={_.first(_.get(product, 'extendedData.images'))}
                alt={'product img'}
              />
              {!isRemovedItem && (
                <img
                  className={`remove-item-icon ${
                    isInvalidCart() ? 'invalid-line-item-remove-icon' : ''
                  }`}
                  src={removeIcon}
                  alt={'remove item'}
                  style={{ cursor: 'pointer' }}
                  onClick={() => removeCartItem(item.lineItemId)}
                />
              )}
            </div>
            <p
              key={i}
              className={`m-0 ${
                isInvalidCart()
                  ? 'invalid-cart-listing-name'
                  : 'cart-listing-name'
              }`}
            >
              {_.get(product, 'extendedData.name', '')}
            </p>
            <p className={'m-0 mx-2 cart-listing-item-price'}>
              {`₹${item.quantity * _.get(product, 'mrp')}`}
            </p>
            {!isRemovedItem && (
              <SelectWithNumbers
                label={'Qty'}
                value={item.quantity}
                onChange={e => updateCartItemQty(item.lineItemId, e)}
                maxValue={
                  isInvalidCart() && !isLineItemValid(item.sku)
                    ? item.quantity
                    : 11
                }
                disabled={isRemovedItem}
              />
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className={'cart h-100'}>
      <div className={'cart-listing-header'}>
        <div className={'cart-listing-header-text mx-4'}>
          <p className={'m-0'}>
            {_.get(
              AVAILABLE_CHECKOUTS,
              `${checkoutOption}.cartPageTitle`,
              'My Cart'
            )}
          </p>
        </div>
        <div>
          <Badge badgeContent={_.sumBy(cartItems, 'quantity')} color="primary">
            {checkoutOption === AVAILABLE_CHECKOUTS.Store.key ? (
              <img
                className={'cart-icon'}
                src={summaryIcon}
                alt="summary_icon"
              />
            ) : (
              <img className={'cart-icon'} src={cartIcon} alt="cart_icon" />
            )}
          </Badge>
        </div>
      </div>
      <div>
        <p className={'m-0 cart-tc my-1'}>
          {'* Price listed is not inclusive of tax'}
        </p>
      </div>
      <div className={`cart-listing ${isInvalidCart() ? 'h-60' : ''}`}>
        {isInvalidCart() && getCartItems(values(removedCartItems))}
        {getCartItems(cartItems)}
        {_.isEmpty(cartItems) && (
          <div className={'empty-cart-container'}>
            <div className={'cart-empty-text'}>
              {_.get(
                AVAILABLE_CHECKOUTS,
                `${checkoutOption}.cartPageMainHeader`,
                'Your cart is empty!'
              )}
            </div>
            <div className={'add-to-cart-text'}>
              {_.get(
                AVAILABLE_CHECKOUTS,
                `${checkoutOption}.cartPageSubHeader`,
                'Add products to cart now.'
              )}
            </div>
          </div>
        )}
      </div>
      <div className={'cart-summary-container'}>
        <div className={'cart-summary-subtotal'}>
          <p className={'m-0 my-2'}>
            Sub total{' '}
            <span
              style={{ fontWeight: 'normal', fontSize: '12px' }}
            >{`(${_.sumBy(cartItems, 'quantity')} items)`}</span>
          </p>
        </div>
        <div className={'cart-summary-total'}>
          <p className={'m-0 my-2'}>{`₹ ${getTotalCartAmount(
            cartItems,
            allProducts
          )}`}</p>
        </div>
        <div className={'cart-summary-save-price'}>
          <p className={'m-0 my-2'}>Saved ₹ 0</p>
        </div>
      </div>
      {getFooter()}
      <FlipbookCheckoutPopup
        checkoutOption={checkoutOption}
        setCartItems={setCartItems}
        cartItems={cartItems}
        allProducts={allProducts}
        CheckoutFormSteps={CheckoutFormSteps}
        isCheckoutFormOpen={isCheckoutFormOpen}
        handleProductPopUpClose={handleProductPopUpClose}
        setIsCustomerAuthenticated={setIsCustomerAuthenticated}
        currentStore={currentStore}
        storeDetails={storeDetails}
        whatsAppUrl={whatsAppUrl}
        name={name}
        setName={setName}
      />
    </div>
  );
};

export default MyCart;
