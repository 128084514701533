import React, { FC, useContext, useEffect, useState } from 'react';
import './CheckoutForm.css';
import { Button, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
  generateOtp,
  getEcomCart,
  validateOtp
} from '../../../services/ecomCart.service';
import _, { isUndefined } from 'lodash';
import {
  isEcomUserLoggedIn,
  updateFlipbookCart
} from '../Utiles/FlipbookUtiles';
import Snackbar from '@material-ui/core/Snackbar';
import { USER_TOKEN } from '../Utiles/FlipbookConfig';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';

interface LoginFormProps {
  tenantName: string;
  setSkipLoginStep: (data: any) => void;
  setCartItems: (data: any) => void;
  mobileNumber: any;
  setMobileNumber: (data: any) => void;
  setShowNavigationBtn: (data: boolean) => void;
  setIsNextEnabled: (data: boolean) => void;
  storeCode: any;
}

export const LoginForm: FC<LoginFormProps> = ({
  tenantName,
  setSkipLoginStep,
  setCartItems,
  mobileNumber,
  setMobileNumber,
  setShowNavigationBtn,
  setIsNextEnabled,
  storeCode
}) => {
  const [showMobileForm, setShowMobileForm] = React.useState(true);
  const [showOtp, setShowOtp] = React.useState(false);
  const [otp, setOtp] = React.useState('');
  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(
    isEcomUserLoggedIn()
  );
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { business } = useContext(BusinessUnitContext);
  const businessId = business.id;
  useEffect(() => {
    if (isUserLoggedIn) {
      setShowNavigationBtn(true);
      setIsNextEnabled(true);
    }
  }, [isUserLoggedIn, setIsNextEnabled, setShowNavigationBtn]);

  const onClickLogout = () => {
    localStorage.clear();
    setIsUserLoggedIn(false);
    setShowMobileForm(true);
    setShowOtp(false);
    setCartItems([]);
    setShowNavigationBtn(false);
  };
  const openErrorMessage = (command: any) => {
    setErrorMessage(_.get(command, 'error.message', 'Something went wrong'));
    setOpen(true);
  };
  const onClickGetOtp = () => {
    generateOtp(mobileNumber, tenantName, businessId).then(
      res => {
        if (!_.has(res, 'error')) {
          setShowMobileForm(false);
          setShowOtp(true);
          setIsUserLoggedIn(false);
        } else {
          openErrorMessage(res);
        }
      },
      err => {
        openErrorMessage(err);
      }
    );
  };
  function handleClose() {
    setOpen(false);
  }
  const onClickBack = () => {
    setIsUserLoggedIn(false);
    setShowOtp(false);
    setShowMobileForm(true);
    setOtp('');
  };

  const onClickSubmitOtp = () => {
    validateOtp(mobileNumber, otp, tenantName, businessId, storeCode).then(
      async res => {
        if (!_.has(res, 'error')) {
          setOtp('');
          localStorage.setItem('tokenType', USER_TOKEN);
          localStorage.setItem('token', _.get(res, 'data.token'));
          localStorage.setItem('tokenCreatedAt', _.get(res, 'data.created_at'));
          localStorage.setItem('tokenExpiresIn', _.get(res, 'data.expires_in'));
          await updateCart();
          setSkipLoginStep(1);
          setShowNavigationBtn(true);
        } else {
          openErrorMessage(res);
        }
      },
      err => {
        openErrorMessage(err);
      }
    );
  };

  function updateCart() {
    !isUndefined(businessId) &&
      getEcomCart(businessId).then(res => {
        setCartItems(updateFlipbookCart(res));
      });
  }

  return (
    <div className={'login-form-container'}>
      <div>
        {isUserLoggedIn ? (
          <div>
            <Button
              variant="contained"
              color="primary"
              className={'mx-2 change-number-btn'}
              onClick={() => onClickLogout()}
            >
              {'Change My Number'}
            </Button>
            <Alert className={'change-number-alert'} severity="warning">
              Warning — Your Current Cart Information Will Be Lost !
            </Alert>
          </div>
        ) : (
          <div>
            {showMobileForm && (
              <div className={'login-form-grid'}>
                <TextField
                  id="outlined-number"
                  label="Mobile Number"
                  type="number"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  value={mobileNumber}
                  onChange={e => {
                    const value = e.target.value;
                    setMobileNumber(value);
                  }}
                />
                <Button
                  disabled={mobileNumber.length !== 10}
                  variant="contained"
                  color="primary"
                  style={{ fontSize: 12, height: 'fit-content' }}
                  onClick={() => onClickGetOtp()}
                >
                  {'Get OTP'}
                </Button>
              </div>
            )}
            {showOtp && (
              <div className={'login-form-grid'}>
                <TextField
                  id="outlined-number"
                  label="OTP"
                  type="number"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  value={otp}
                  onChange={e => {
                    const value = e.target.value;
                    setOtp(value);
                  }}
                />
                <div>
                  <Button
                    variant="contained"
                    style={{ fontSize: 12, height: 'fit-content' }}
                    onClick={() => onClickBack()}
                    className={'mx-2'}
                  >
                    {'back'}
                  </Button>
                  <Button
                    disabled={otp.length < 4 || otp.length >= 7}
                    variant="contained"
                    color="primary"
                    className={'mx-2'}
                    style={{ fontSize: 12, height: 'fit-content' }}
                    onClick={() => onClickSubmitOtp()}
                  >
                    {'submit'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
        <Snackbar
          open={open}
          onClose={handleClose}
          message={errorMessage}
          autoHideDuration={1500}
        />
      </div>
    </div>
  );
};

export default LoginForm;
