/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../config';

function getConfigDetails(type, filter) {
  const { businessUnitId } = filter;
  const stringQueryParams = {
    buId: businessUnitId,
    type: type
  };

  const url = new URL(`${config.ruleServerUrl}/users/config`);
  url.search = new URLSearchParams(stringQueryParams);
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      console.error(err);
      return {};
    });
}

function saveConfigDetails(type, filter, configDetails) {
  const { businessUnitId } = filter;
  const stringQueryParams = {
    buId: businessUnitId,
    type: type
  };
  const url = new URL(`${config.ruleServerUrl}/users/config`);
  url.search = new URLSearchParams(stringQueryParams);
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({
      ...configDetails
    }),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return err;
    });
}

function saveChannelsDetails(data) {
  const url = new URL(`${config.ruleServerUrl}/users/config/channels`);

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({
      ...data
    }),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}
function getChannelsDetails(business) {
  let url;
  if (business && business !== '0') {
    url = new URL(
      `${config.ruleServerUrl}/users/config/channels?businessUnit=${business}`
    );
  } else {
    url = new URL(`${config.ruleServerUrl}/users/config/channels`);
  }

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function userOTPDetails(data) {
  const url = new URL(`${config.ruleServerUrl}/users/config/otp`);

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function getuserOTPDetails(businessUnit) {
  const url = new URL(
    `${config.ruleServerUrl}/users/config/otp/${businessUnit}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function redemptionDetails(data, type, buId) {
  const url = new URL(
    `${config.ruleServerUrl}/users/config?type=${type}&buId=${buId}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function getRedemptionDetails(buId) {
  const url = new URL(
    `${config.ruleServerUrl}/users/config?type=loyalty&buId=${buId}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function loyaltySavePassword(data) {
  const url = new URL(
    `${config.ruleServerUrl}/users/roles/adhocPointsPassword `
  );

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({
      ...data
    }),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function searchLoyaltyException(searchData, businessUnitId) {
  const url = new URL(
    `${config.ruleServerUrl}/users/casa-product-master/businessUnit/${businessUnitId}/product/${searchData}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function saveLoyaltyConfig(filter, offerConfigDetails) {
  return saveConfigDetails('loyalty', filter, offerConfigDetails);
}

function getLoyaltyConfig(filter) {
  return getConfigDetails('loyalty', filter);
}

const getChannelConfig = business => {
  return getChannelsDetails(business);
};

const saveChannelsConfig = data => {
  return saveChannelsDetails(data);
};

const userOTPConfig = data => {
  return userOTPDetails(data);
};

const getUserOTPConfig = businessUnit => {
  return getuserOTPDetails(businessUnit);
};

const redemptionConfig = (data, type, buId) => {
  return redemptionDetails(data, type, buId);
};

const getRedemptionConfig = buId => {
  return getRedemptionDetails(buId);
};

const loylatySavePasswordConfig = data => {
  return loyaltySavePassword(data);
};

const searchLoyaltyExceptionConfig = (searchData, businessUnitId) => {
  return searchLoyaltyException(searchData, businessUnitId);
};

export default {
  getConfigDetails,
  saveConfigDetails,
  saveLoyaltyConfig,
  getLoyaltyConfig,
  getChannelConfig,
  saveChannelsConfig,
  userOTPConfig,
  getUserOTPConfig,
  redemptionConfig,
  getRedemptionConfig,
  loylatySavePasswordConfig,
  searchLoyaltyExceptionConfig
};
