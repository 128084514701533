import React from 'react';
import { Typography, Button, MuiThemeProvider } from '@material-ui/core';
import { getCurrentUser } from '../../services/user.service';
import config from '../../config';
import RewardConfig from './RewardConfig';
import theme from '../OfferDefinition/theme';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import './styles.css';
import Loader from '../Campaign/shared/components/Loader/Loader';
import { ParentLink } from '../../utils/ParentLink';

async function getAllRewardsConfiguredByTenant(tenantId, offset, limit) {
  return fetch(
    `${config.ruleServerUrl}/social-media/tenant/${tenantId}/all-channel-config/${offset}/${limit}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
}

class SocialMediaRewardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configs: [],
      limit: 5,
      loading: true,
      fbAccessToken: null,
      count: 0
    };
  }

  componentDidMount = async () => {
    const { limit } = this.state;
    const user = await getCurrentUser();
    const { tenant } = user;
    try {
      const {
        data,
        count,
        fbAccessToken
      } = await getAllRewardsConfiguredByTenant(tenant.id, 0, limit);
      this.setState({
        configs: data,
        count: count,
        fbAccessToken: fbAccessToken,
        loading: false
      });
    } catch (error) {
      console.error(error);
    }
  };

  getAccessToken = async () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const user = await getCurrentUser();
    const { tenant } = user;
    return fetch(
      `${config.ruleServerUrl}/social-media/tenant/${tenant.id}/access-token`,
      {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
      }
    )
      .then(r => r.json())
      .then(r => {
        that.setState({
          fbAccessToken: r.accessToken
        });
      });
  };

  fetchMoreConfigs = async () => {
    const { configs, limit } = this.state;
    this.setState({ loading: true });

    const user = await getCurrentUser();
    const { tenant } = user;

    const { data, count } = await getAllRewardsConfiguredByTenant(
      tenant.id,
      configs.length,
      limit
    );

    this.setState(prevState => ({
      configs: [...prevState.configs, ...data],
      count: count,
      loading: false
    }));
  };

  render() {
    console.log(this.state);
    const { configs, count, fbAccessToken, loading } = this.state;

    if (loading) {
      return <Loader />;
    }
    const doesHaveRewardsConfigured = fbAccessToken && configs.length > 0;
    return (
      <MuiThemeProvider theme={theme.theme}>
        <div
          style={{ display: 'flex', alignItems: 'center', marginRight: '10%' }}
        >
          <h2
            style={{
              padding: '50px 10%',
              margin: 0,
              flex: 5,
              marginLeft: 18,
              fontSize: 27
            }}
          >
            Engagement set for Social Media Posts
          </h2>
          <div style={{ flex: 5 }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: '#F2755F',
                color: 'white',
                borderRadius: 5,
                float: 'right'
              }}
              className="socialMediaEngagementsBtn"
              // onClick={function() {
              //   window.top.location.href =
              //     config.dashboardUrl + '/social-media-engagements/create';
              // }}
              component={ParentLink}
              to={'/social-media-engagements/create'}
            >
              <span style={{ margin: 2, color: 'white !important' }}>
                Create New Engagement
              </span>
            </Button>
          </div>
        </div>
        {doesHaveRewardsConfigured ? (
          <div style={{ marginBottom: 50 }}>
            <div
              style={{
                margin: '0 10%',
                padding: 10,
                borderRadius: 5
              }}
            >
              {fbAccessToken &&
                configs.map((config, idx) => (
                  <RewardConfig data={{ ...config, idx }} key={idx} />
                ))}
            </div>
            {count >= configs.length && (
              <div style={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    borderWidth: 2,
                    height: 40,
                    width: 150,
                    marginTop: 20,
                    outline: 'none',
                    textTransform: 'none'
                  }}
                  onClick={() => this.fetchMoreConfigs()}
                >
                  <Typography style={{ fontSize: 20 }}>Next</Typography>
                  <ArrowForwardIosIcon style={{ color: '#FFFFFF' }} />
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              margin: '0 10%',
              padding: 10,
              borderRadius: 5,
              textAlign: 'start'
            }}
          >
            <Typography> No Rewards Configured.</Typography>
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}

export default SocialMediaRewardList;
