import { entries, reduce, get, sortBy, flatten } from 'lodash';
import SectionField from '../../DynamicInvoiceGenerator/models/SectionField';
import type InitialSectionState from '../modals/InititalSectionState';
import { InitialSectionField } from './../modals/InititalSectionState';
import configs from './configs';

type getIntialStateFnType = (fields: SectionField[], extraFields?: SectionField[]) => InitialSectionState;
const getInitialValuesByGetter: getIntialStateFnType = (fields, extraFields = []) => {
  const defaultState = reduce(
    fields,
    (acc, { getter, getterOptions = [], selectedGetterOption, ...field }) => {
      acc[getter] = { ...field, req: get(field,"req",true), getterOptions,selectedGetterOption  };
      return acc;
    },
    {} as InitialSectionState
  );
  return assignExtra(defaultState, extraFields);
}

const getInitialValuesById: getIntialStateFnType = (fields, extraFields = []) => {
  const defaultState = reduce(
    fields,
    (acc, field) => {
      const key = field.id || field.getter
      acc[key] = { ...field, req: field.req ?? true };
      return acc;
    },
    {} as InitialSectionState
  );
  return assignExtraWithIdx(defaultState, extraFields);
}

function assignExtra(defaultState: InitialSectionState, additionalFields: SectionField[]) {
  const alteredState = reduce(
    additionalFields,
    (acc, { getter, req, ...rem }) => {
      acc[getter] = { req: req ?? false, ...rem };
      return acc;
    },
    defaultState
  );
  return alteredState
}

function assignExtraWithIdx(defaultState: InitialSectionState, additionalFields: SectionField[]) {
  const alteredState = reduce(
    additionalFields,
    (acc, { req, id, ...rest }) => {
      acc[id!] = { req: req ?? false, ...rest };
      return acc;
    },
    defaultState
  );
  return alteredState
}

type accType = { [key: string]: string }

function getLabelReducer(reduceKeyGetter: string) {
  return (acc: accType, f: SectionField) => {
    acc[get(f, reduceKeyGetter)] = f.label;
    return acc;
  }
}

function getDefaultFieldLabels(type: string, key = "getter") {
  const fields = configs.sectionConfig[type].fields;
  const labels = reduce(fields, getLabelReducer(key), {} as accType);
  return labels;
}

function getLabelsWithAdditional(type: string,key = "getter") {
  const extraFields = configs.additionalConfigFields[type] || [];
  const defaultLabels = getDefaultFieldLabels(type);
  const labels = reduce(extraFields, getLabelReducer(key), defaultLabels as accType);
  return labels;
}

function getRequiredLabels(type: string, extraFields?: SectionField[],key = "getter") {
  const reqExtraFields = extraFields?.filter(({ req }) => req)
  const defaultLabels = getDefaultFieldLabels(type);
  const labels = reduce(reqExtraFields, getLabelReducer(key), defaultLabels as accType);
  return labels;
}

function getOrderedLabels(type: string, extraFields?: SectionField[],key = "getter"){
  const reqExtraFields = extraFields?.filter(({ req }) => req)
  const fields = sortBy(flatten([
    configs.sectionConfig[type].fields, 
    reqExtraFields
  ]), f => parseInt(f?.value || "0")
  )
  console.log("fields", fields);
  return fields.map(f => f?.label);
}

function transformFields(inputFields: InitialSectionState) {
  const getSectionField = (keyGetter: string, field: InitialSectionField) => {
    let { label = "", value = "", getter = keyGetter, getterOptions, selectedGetterOption } = field;
    return new SectionField({ ...field, getter, label, value, getterOptions, selectedGetterOption });
  }

  const trnsformer = ([key, field]: [string, InitialSectionField]) => getSectionField(key, field)

  let sectionFields = entries(inputFields).map(trnsformer)
  return sectionFields
}

export default { getInitialValuesByGetter, getInitialValuesByIndex: getInitialValuesById, getDefaultFieldLabels, getLabelsWithAdditional, getRequiredLabels, getOrderedLabels,transformFields };
