import React, { FC, useCallback, useContext, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import _, { find, head, isUndefined } from 'lodash';
import './ProductCard.css';
import { createStyles, Drawer, Theme, Tooltip } from '@material-ui/core';
import { ProductCardPopUp } from './ProductCardPopUp';
import Modal from '@material-ui/core/Modal';
import { Skeleton } from '@material-ui/lab';
import {
  skeletonCardHeight,
  skeletonCardWidth
} from '../Utiles/FlipbookUtiles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
  getActualPrice,
  getHeadImage,
  getSavePrice,
  getSellingPrice
} from '../Utiles/FlipbookProducts';
import { TileDataCollection } from '../../../models/FlipbookV2/TileData/TileDataCollection.model';
import get from 'lodash/get';
import { FlipbookContext } from '../Hooks/FlipbookContext';
import { CheckoutOption } from '../../../models/FlipbookV2/flipbookV2.model';
import { FlipbookCartContext } from '../Hooks/FlipbookCartContext';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';

const useModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '30ch'
      }
    }
  })
);
const useStyles = makeStyles({
  root: {
    minWidth: 198,
    height: '100%',
    padding: '0.4rem'
  }
});
const ProductNameToolTip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip);

interface AddProductTileProps {
  tileDataCollection: TileDataCollection;
  isProductLoaded: boolean;
  isStockLoaded: boolean;
  productHierarchy?: any;
  allProducts?: any;
  isPersonalised?: boolean;
}

export const ProductCard: FC<AddProductTileProps> = ({
  tileDataCollection,
  productHierarchy,
  allProducts,
  isProductLoaded,
  isStockLoaded,
  isPersonalised = false
}) => {
  const flipbookCartContext = useContext(FlipbookCartContext);
  const businessUnitContext = useContext(BusinessUnitContext);
  const cartItems = flipbookCartContext.cartItems;
  const setCartItems = flipbookCartContext.setCartItems;
  const [selectedProduct, setSelectedProduct] = React.useState<any>();
  const productHierarchyNew = _.get(
    businessUnitContext,
    'business.productHierarchyNew',
    []
  );
  const getShrinkedName = (centerName: string) => {
    let concatString = _.get(centerName.match(/.{1,10}/g), '0', '');
    concatString =
      concatString.length === centerName.length
        ? concatString
        : concatString.concat('...');
    return {
      shrink: concatString,
      actualString: centerName
    };
  };
  const isMobileView = window.screen.width <= 600;
  const getHierarchyValueFromProduct = (data: any, key: string) => {
    return _.get(data, `extendedData.${key}`, '');
  };
  const flipbookContext = useContext(FlipbookContext);
  const currentFlipbookData = flipbookContext.flipbookContext;
  const productCardTemplate = tileDataCollection.productCardTemplate;
  const data: any = {};
  const getHeadProductFromSkuList = useCallback(() => {
    const flatSkuList = get(tileDataCollection, 'currentPageSKUS.0.sku');
    const firstSku = head(flatSkuList);
    return find(allProducts, product => {
      return get(product, 'extendedData.sku') === firstSku;
    });
  }, [allProducts, tileDataCollection]);
  useEffect(() => {
    if (!isUndefined(selectedProduct)) {
      return;
    }
    const firstProduct = getHeadProductFromSkuList();
    setSelectedProduct(firstProduct);
  }, [getHeadProductFromSkuList, selectedProduct]);
  const savePrice = !_.isEmpty(selectedProduct)
    ? getSavePrice(selectedProduct)
    : 0;
  const sellingPrice = !_.isEmpty(selectedProduct)
    ? getSellingPrice(selectedProduct)
    : 0;
  const actualPrice = !_.isEmpty(selectedProduct)
    ? getActualPrice(selectedProduct)
    : 0;
  const productImage = getHeadImage(selectedProduct) || '';
  data.savePrice = savePrice;
  data.sellingPrice = sellingPrice;
  data.actualPrice = actualPrice;
  data.productImage = productImage;
  data.inStock = _.get(selectedProduct, 'inStock', '');
  data.backorderable = _.get(selectedProduct, 'backorderable', '');
  data.countOnHand = _.get(selectedProduct, 'countOnHand', 0);
  data.selectedProduct = selectedProduct;
  const fieldMapping = find(
    productHierarchyNew,
    ph => get(ph, 'casaFieldName') === productCardTemplate.title
  );
  const titleName = get(fieldMapping, 'displayName', productCardTemplate.title);
  const productTileHierarchyTitle = getHierarchyValueFromProduct(
    selectedProduct,
    titleName
  );
  const fieldMappingSubTitle = find(
    productHierarchyNew,
    ph => get(ph, 'casaFieldName') === productCardTemplate.subTitle
  );
  const subTitle = get(
    fieldMappingSubTitle,
    'displayName',
    productCardTemplate.subTitle
  );
  const productTileHierarchyDescription = getHierarchyValueFromProduct(
    selectedProduct,
    subTitle
  );
  const updatedProductTileTitle = getShrinkedName(
    productTileHierarchyTitle || ''
  );
  const updatedProductTileDescription = getShrinkedName(
    productTileHierarchyDescription || ''
  );
  // const productName = getShrinkedName(
  //   _.get(data, 'selectedProduct.extendedData.name', '')
  // );
  const [isProductPopUp, setIsProductPopUp] = React.useState(false);
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const handleProductPopUpOpen = () => {
    // TODO: enable it when product pop task is over.
    setIsProductPopUp(true);
  };
  const handleProductPopUpClose = () => {
    setIsProductPopUp(false);
  };
  const getProductCardPopUp = () => {
    return (
      <ProductCardPopUp
        tileDataCollection={tileDataCollection}
        data={data}
        setSelectedProduct={selectedProduct1 =>
          setSelectedProduct(selectedProduct1)
        }
        productHierarchy={productHierarchy}
        allProducts={allProducts}
        cartItems={cartItems}
        setCartItems={setCartItems}
        checkoutFlow={
          currentFlipbookData?.checkoutOption || CheckoutOption.FlipbookCheckout
        }
        handleProductPopUpClose={handleProductPopUpClose}
        isStockLoaded={isStockLoaded}
      />
    );
  };

  return (
    <div>
      {isProductLoaded ? (
        <Card
          className={`${classes.root} product-card-container`}
          variant={'outlined'}
        >
          <div className={'product-container'}>
            <div className={'product-tile-content'}>
              <div>
                <div className={'image-container'}>
                  {isPersonalised && (
                    <div className={'image-personalisation-container'}>
                      <div className={'img-personalisation-text'}>
                        {'Just for You'}
                      </div>
                    </div>
                  )}
                  <LazyLoadImage
                    alt={_.head(productImage)}
                    src={_.head(productImage)}
                    effect={'blur'}
                    onClick={handleProductPopUpOpen}
                    className={'image-container'}
                    style={{ cursor: 'pointer' }}
                  />
                  {isMobileView ? (
                    <React.Fragment key={'bottom'}>
                      <Drawer
                        anchor={'bottom'}
                        open={isProductPopUp}
                        onClose={() => handleProductPopUpClose()}
                      >
                        {getProductCardPopUp()}
                      </Drawer>
                    </React.Fragment>
                  ) : (
                    <Modal
                      open={isProductPopUp}
                      onClose={() => handleProductPopUpClose()}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                      className={modalClasses.modal}
                    >
                      {getProductCardPopUp()}
                    </Modal>
                  )}
                </div>
                <div
                  onClick={handleProductPopUpOpen}
                  style={{ cursor: 'pointer' }}
                  className={'product-cart-info'}
                >
                  <ProductNameToolTip
                    title={updatedProductTileTitle.actualString}
                    aria-label="add"
                  >
                    <div className={'product-font product-content'}>
                      {updatedProductTileTitle.actualString}
                    </div>
                  </ProductNameToolTip>
                  <ProductNameToolTip
                    title={updatedProductTileDescription.actualString}
                    aria-label="add"
                  >
                    <div className={'product-font product-content-description'}>
                      {updatedProductTileDescription.actualString}
                    </div>
                  </ProductNameToolTip>
                  <div className={'product-tile-pricing'}>
                    <div className={'product-tile-base-price'}>
                      <span className={'product-price'}>
                        {actualPrice && (
                          <div className={'product-tile-mop'}>
                            ₹ {actualPrice}
                          </div>
                        )}
                        <span className={'product-font product-tile-mrp'}>
                          {'₹ '} {sellingPrice}{' '}
                        </span>
                      </span>
                    </div>
                    <span className={'product-tile-cart'}></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card
          className={`${classes.root} product-card-container`}
          variant={'outlined'}
        >
          <div>
            {/*<div className={'product-tile-footer'}>
              <Skeleton
                className={'mx-1'}
                variant="text"
                animation="wave"
                width="30%"
              />
              <Skeleton
                className={'mx-1'}
                variant="text"
                animation="wave"
                width="30%"
              />
            </div>*/}
            <Skeleton
              className={'mx-1 my-1'}
              variant="rect"
              animation="wave"
              width={skeletonCardWidth()}
              height={skeletonCardHeight()}
            />
            <div className={'product-cart-info'}>
              <Skeleton
                variant="text"
                className={'mx-1'}
                animation="wave"
                width="60%"
                height={22}
              />
              <Skeleton
                variant="text"
                className={'mx-1'}
                animation="wave"
                width="20%"
                height={16}
              />
              <Skeleton
                variant="text"
                className={'mx-1'}
                animation="wave"
                width="50%"
                height={18}
              />
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};
export default ProductCard;
