/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';

import FieldTypes from '../models/FieldTypes';
import SectionField, { SectionFieldParams } from '../models/SectionField';
import computedFieldUtils from './ComputedFields';
import formatters from './FieldFormatters';

function transformToType(field: SectionFieldParams): string {
  const { type, value, getter } = field;
  switch (type) {
    case FieldTypes.DATE:
      return formatters.formatDate(value);
    case FieldTypes.CURRENCY:
      return formatters.formatCurrency(value);
    case FieldTypes.IMAGE:
      return formatters.formatImage(value, getter);
    case FieldTypes.LINK:
      return formatters.formatLink(value, getter);
    case FieldTypes.HTML_CONTENT:
      return formatters.formatHtmlContent(value);
    default:
      return formatters.formatDefault(value);
  }
}

function getValueFromData(configData: SectionFieldParams, data: any): string {
  const {
    value = '',
    getter = '',
    getterOptions,
    selectedGetterOption
  } = configData;
  const defaultValue = value || '-';
  if (selectedGetterOption && !_.isEmpty(getterOptions))
    return _.get(data, getterOptions![selectedGetterOption], defaultValue);

  return _.get(data, getter, defaultValue);
}

function getAndTransformValue(
  configData: SectionFieldParams,
  data: any
): string {
  if (configData.type === FieldTypes.COMPUTED) {
    return getComputedField(configData, data);
  }
  const value = getValueFromData(configData, data);
  configData = { ...configData, value };
  return transformToType(configData);
}

function getComputedField(configData: SectionFieldParams, data: any) {
  const computedFieldType = configData.getter;
  return computedFieldUtils.computedFieldGetterMap[computedFieldType](
    data,
    configData
  );
}

function getHSNComputedField(configData: SectionFieldParams, data: any) {
  const computedFieldType = configData.getter;
  return computedFieldUtils.hsnFieldGetterMap[computedFieldType](data);
}

function getValueFromPaymentSplit(paymentSplit: any): number {
  return parseFloat(_.get(paymentSplit, 'value', 0));
}

function isValidPayment(paymentSplit: any) {
  return (
    getValueFromPaymentSplit(paymentSplit) !== 0 &&
    _.get(paymentSplit, 'mode', _.get(paymentSplit, 'name', false))
  );
}

function transformPaymentSplitToField(paymentSplit: any): SectionFieldParams {
  const { mode = '', name = '', value } = paymentSplit;
  const sectionField = {
    label: name === '' ? mode : `${mode} - ${name}`,
    value,
    type: 'currency',
    getter: ''
  };
  return new SectionField(sectionField);
}

function transformPaymentSplitsToFields(billData: any) {
  const paymentSplits = _.get(billData, 'bill.paymentSplits', []);
  const validUserPayments = _.filter(paymentSplits, isValidPayment);
  return _.map(validUserPayments, transformPaymentSplitToField);
}

export default {
  transformToType,
  getValueFromData,
  getAndTransformValue,
  getComputedField,
  getHSNComputedField,
  transformPaymentSplitsToFields
};
