import React, { useContext, useEffect, useState } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import _ from 'lodash';
import { SectionType } from '../../../../DynamicInvoiceGenerator/InvoiceGenerator';
import SectionField from '../../../../DynamicInvoiceGenerator/models/SectionField';
import { InvoiceContext } from '../../../context/InvoiceContext';
import { Heading } from '../../../shared/Heading';
import sectionUtils from '../../../utils/sectionUtils';
import InputField from '../../InputField/InputField';
import AntSwitch from '../../Switch/AntSwitch';
import classes from './OrderDetails.module.scss';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import useDebounce from '../../../shared/use-debounce';
import CheckIcon from '@material-ui/icons/Check';
import InitialSectionState, {
  InitialSectionField
} from '../../../modals/InititalSectionState';

// invoice details has 3 section the main table (product list -> ORDER_LIST)
// 2 summary table -> ORDER_SUMMARY
// 3.HsnSummary table -> HSN_SUMMARY
const type = SectionType.ORDER_LIST;
const OrderDetails: React.FC = () => {
  const invoiceContext = useContext(InvoiceContext);
  const { fields, additionalFields } = invoiceContext.getConfig(type);

  const initialState = sectionUtils.getInitialValuesByGetter(
    fields,
    additionalFields
  );
  const fieldLabels = sectionUtils.getLabelsWithAdditional(type);

  console.log('all labels', fieldLabels);

  const [inputFields, setInputFields] = useState(initialState);
  const debouncedFields = useDebounce(inputFields, 500);
  const [selectedIdx, setSelectedIdx] = useState(0);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target;
    const targetInput = inputFields[name];

    setInputFields(prev => ({
      ...prev,
      [name]: { ...targetInput, label: value }
    }));
  };

  const getMaxOrderPriority = (inputFields: InitialSectionState) =>
    _.reduce(
      inputFields,
      (a: number, f: InitialSectionField): number => {
        const value = parseInt(f.value!);
        return value > a && value < 10 ? value : a;
      },
      10
    );

  useEffect(() => {
    invoiceContext.setConfig(type, inputFields);
    console.log('inputFields', inputFields);
    // eslint-disable-next-line
  }, [debouncedFields, selectedIdx]);

  function renderTable() {
    const headLabels = sectionUtils.getOrderedLabels(type, additionalFields);
    return (
      <table className={classes.detailsTable}>
        <thead>
          {_.values(headLabels).map((head, index) => (
            <th key={index}>{head}</th>
          ))}
        </thead>
      </table>
    );
  }

  function renderField({ getter, type }: SectionField) {
    console.log('label', inputFields[getter].label);
    return (
      <div className={classes.fields} key={getter}>
        <InputField
          type={type}
          onChange={handleChange}
          name={getter}
          placeholder={fieldLabels[getter]}
          label={'Default Field Name'}
          disabled={true}
        />
        <InputField
          type={type}
          onChange={handleChange}
          name={getter}
          placeholder={inputFields[getter].label}
          label={fieldLabels[getter]}
        />
        <div className={classes.switch}>
          <AntSwitch
            checked={inputFields[getter].req ?? false}
            onChange={e => {
              const maxOrderPriority = getMaxOrderPriority(inputFields);
              setInputFields(prev => ({
                ...prev,
                [getter]: {
                  ...prev[getter],
                  req: e.target.checked,
                  value: (maxOrderPriority + 1).toString()
                }
              }));
            }}
            name={getter}
          />
          <p>Show the field in the invoice</p>
        </div>
      </div>
    );
  }

  function renderForm() {
    const field = additionalFields[selectedIdx];
    return <form className={classes.form}>{field && renderField(field)}</form>;
  }

  function renderGetterSelector() {
    const selectedField = additionalFields[selectedIdx];

    if (
      _.isEmpty(selectedField?.getterOptions) &&
      _.isEmpty(selectedField.selectedGetterOption)
    )
      return <></>;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          textAlign: 'center',
          width: '40%'
        }}
      >
        <p
          style={{
            fontWeight: '400',
            fontSize: '20px'
          }}
        >
          Field Options
        </p>
        <div className={classes.select}>
          <Select
            value={selectedField.selectedGetterOption || 0}
            className={classes.input}
            onChange={e => {
              let idx = e.target.value as number;
              setInputFields(prev => ({
                ...prev,
                [selectedField.getter]: {
                  ...prev[selectedField.getter],
                  selectedGetterOption: idx
                }
              }));
              return console.log(idx);
            }}
            variant="outlined"
            IconComponent={ExpandMoreRoundedIcon}
          >
            {selectedField.getterOptions?.map((f, idx) => {
              return (
                <MenuItem
                  value={idx}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem'
                  }}
                >
                  {f}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
    );
  }
  function renderExtraColumns() {
    return (
      <>
        <div className={classes.head}>
          <p>Add another column</p>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width={30}
          >
            <path
              fill-rule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
              clip-rule="evenodd"
            />
          </svg> */}
        </div>
        <div className={classes.body}>
          <div className={classes.selectGroup}>
            <div className={classes.select}>
              <Select
                value={selectedIdx}
                onChange={e => {
                  let idx = e.target.value as number;
                  setSelectedIdx(idx);
                }}
                className={classes.input}
                variant="outlined"
                IconComponent={ExpandMoreRoundedIcon}
              >
                {_.map(
                  additionalFields,
                  (
                    { getter, req = false, getterOptions = ['something'] },
                    idx
                  ) => (
                    <MenuItem
                      value={idx}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem'
                      }}
                    >
                      {fieldLabels[getter]}
                      {req && <CheckIcon />}
                    </MenuItem>
                  )
                )}
              </Select>
            </div>
            {renderGetterSelector()}
          </div>

          {renderForm()}
        </div>
      </>
    );
  }

  return (
    <div className={classes.container}>
      <Heading
        title="Order Details"
        subtitle="Default Table Column (Alter table column name if needed)"
      />
      {renderTable()}
      <div className={classes.extras}>{renderExtraColumns()}</div>
    </div>
  );
};
export default OrderDetails;
