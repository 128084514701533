/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import { styled } from '@material-ui/styles';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import configService from '../../../services/customerGroup.service';
import { toastMessageAction } from '../../../store/action';
import { PaginationBar } from '../../PaginationBar/PaginationBar';

const StyledAppTextField = styled(TextField)({
  '& .MuiOutlinedInput-input ': {
    padding: '14px !important'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    top: '0px !important'
  }
});

const BorderedTableCell = styled(TableCell)({
  textAlign: 'center !important',
  border: '1px solid rgba(224, 224, 224, 1) !important',
  fontWeight: 500
});

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Customer name'
  },
  {
    id: 'mobileNumber',
    numeric: true,
    disablePadding: false,
    label: 'Customer Mobile'
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <BorderedTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </BorderedTableCell>
        {headCells.map((headCell, idx) => (
          <BorderedTableCell
            key={idx}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            style={{ fontWeight: 700, fontSize: 16 }}
          >
            {headCell.label}
          </BorderedTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '40%',
    position: 'relative',
    left: '108px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 300
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  searchInput: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px'
  }
}));

export default function EditCustomerGroupList(props) {
  const { business, status, setStatus } = props;
  const { customerGroupId } = useParams();
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const totalPages = Math.ceil(totalCount / limit);
  const limitOptions = [10, 25, 50, 100];
  const [searchTerm, setSearchTerm] = useState('');
  const [tableRows, setTableRows] = useState([]);
  const dispatch = useDispatch();

  const handleOnClickNextTable = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handleOnClickPreviousTable = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleOnLimitChange = event => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = tableRows.map(n => n.customerId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, customerId) => {
    const selectedIndex = selected.indexOf(customerId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, customerId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = customerId => selected.indexOf(customerId) !== -1;

  console.log(business, 'outside');
  const onSearchTermKeyPress = searchTerm => {
    console.log(business);
    setSearchTerm(searchTerm);
    configService
      .searchAssignedCustomerConfig(
        customerGroupId,
        searchTerm,
        business.name,
        page + 1,
        limit
      )
      .then(({ data }) => {
        const resData = data.data.map(item => {
          return { ...item.tenantCustomer, customerId: item.id };
        });
        setTotalCount(data.totalSize);
        setTableRows([...resData]);
        setPage(0);
      });
  };

  const debouncedResults = useMemo(() => {
    return debounce(searchTerm => onSearchTermKeyPress(searchTerm), 500);
  }, [business.name]);

  useEffect(() => {
    if (business.name !== '0' && searchTerm === '') {
      configService
        .getAssignedCustomerGroup(
          business.name,
          customerGroupId,
          page + 1,
          limit
        )
        .then(({ data }) => {
          const resData = data.data.map(item => {
            return { ...item.tenantCustomer, customerId: item.id };
          });
          setTotalCount(data.totalSize);
          setTableRows([...resData]);
        });
    }
  }, [business.name, customerGroupId, status, page, limit]);

  const handleDeleteCustomer = () => {
    if (selected.length > 0) {
      const deleteData = { ids: selected };
      configService
        .removeBulkCustomerConfig(deleteData, business.name)
        .then(() => {
          setStatus(!status);
          setSearchTerm('');
          dispatch(
            toastMessageAction({
              severity: 'success',
              message: `Customer mobile number delete successfully`,
              messageStatus: true
            })
          );
          setSelected([]);
        });
    }
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.searchInput}>
          <div>
            <StyledAppTextField
              variant={'outlined'}
              defaultValue={searchTerm}
              onInput={c => debouncedResults(c.target.value)}
              placeholder={'Search by number'}
              InputProps={{
                classes: {
                  root: classes.searchBar
                }
              }}
              name="searchNumber"
            />
          </div>
          <IconButton
            onClick={handleDeleteCustomer}
            disabled={selected.length === 0}
          >
            <DeleteIcon />
          </IconButton>
        </div>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={tableRows.length}
            />
            <TableBody>
              {tableRows.map((row, index) => {
                const isItemSelected = isSelected(row.customerId);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row.customerId)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.customerId}
                    selected={isItemSelected}
                  >
                    <BorderedTableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </BorderedTableCell>
                    <BorderedTableCell component="th" id={labelId} scope="row">
                      {row.name}
                    </BorderedTableCell>
                    <BorderedTableCell>{row.mobileNumber}</BorderedTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationBar
          currentPage={page}
          totalPages={totalPages}
          handleOnClickNext={handleOnClickNextTable}
          handleOnClickPrevious={handleOnClickPreviousTable}
          rowsPerPage={limit}
          rowsPerPageOptions={limitOptions}
          handleOnChangeRowsPerPage={handleOnLimitChange}
        />
      </Paper>
    </div>
  );
}
