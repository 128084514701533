/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import Select from 'react-select';
import _, { isEmpty } from 'lodash';
import { BusinessUnitContext } from '../../BusinessUnitContext';
import update from 'immutability-helper';
import GetAppIcon from '@material-ui/icons/GetApp';
import productMasterService from '../../../../services/productMaster.service';
import {
  BaseCondition,
  FieldCondition,
  JoinBucket,
  Operator,
  ProductBucket,
  LogicalOperator,
  ProductDiscount
} from '../../../../models/offer/OfferRule.model';
import { CSVLink } from 'react-csv';
import {
  AggregateTypes,
  LogicalOperators,
  NumericalOperators
} from '../../../../constants';
import CustomCheckbox from '../../CheckBox';
import AddFilter from '../../../Assets/images/add_filter.svg';
import Cross from '../../../Assets/images/cross.svg';
import { ReadOnlyContext } from '../../../OfferDetail/ReadOnlyContext';
import FamilyConfigurations from './FamilyConfigurations';
import Discount from './Discount';
import SelectProductHierarchy from './Components/SelectProductHierarchy';
import SelectProductSkus from './Components/SelectProductSkus';

export interface OptionType {
  label: string;
  value: string;
}

function asOptions(values: string[]): OptionType[] {
  return _.map(values, v => ({ label: v, value: v }));
}

interface ProductSelectionProps {
  bucket: ProductBucket;
  setRootBucket: (root: JoinBucket) => void;
  rootBucket: JoinBucket;
}

export const ProductSection: React.FC<ProductSelectionProps> = ({
  bucket,
  setRootBucket,
  rootBucket
}) => {
  const { readOnly } = useContext(ReadOnlyContext);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isHierarchyBased, setIsHierarchyBased] = useState(true);
  const businessUnitContext = useContext(BusinessUnitContext);
  const [fromBetweenValue, setFromBetweenValue] = useState('');
  const [betweenErrorMessage, setBetweenErrorMessage] = useState(false);
  const [isExclusion, setIsExclusion] = useState(false);
  const [productFieldOptions, setProductFieldOptions] = useState<{
    [key: string]: OptionType[];
  }>({});
  const [
    selectedProductFilterOptions,
    setSelectedProductFilterOptions
  ] = useState<{
    [key: string]: OptionType[];
  }>({});
  const [
    selectedExclusionProductFilterOptions,
    setSelectedExclusionProductFilterOptions
  ] = useState<{
    [key: string]: OptionType[];
  }>({});
  const toggleSelectionType = () => {
    setSelectedProductFilterOptions({});
    setSelectedExclusionProductFilterOptions({});
    setIsHierarchyBased(!isHierarchyBased);
  };

  function replaceRootBucket(newBucket: ProductBucket) {
    setRootBucket(rootBucket.replace(newBucket));
  }

  function getFieldConditionExclusionPreviewText(
    condition: FieldCondition
  ): string {
    if (
      condition.fieldAccessor === 'sku' &&
      condition?.operator?.name === 'isNotIn'
    ) {
      return `${_.size(condition.value)} skus`;
    } else if (condition?.operator?.name === 'isNotIn') {
      return _.join(condition.value, ', ');
    } else {
      return '';
    }
  }

  function getFieldConditionPreviewText(condition: FieldCondition): string {
    if (
      condition.fieldAccessor === 'sku' &&
      condition?.operator?.name === 'isIn'
    ) {
      return `${_.size(condition.value)} skus`;
    } else if (condition?.operator?.name === 'isIn') {
      return _.join(condition.value, ', ');
    } else {
      return '';
    }
  }

  const updateDiscount = (discount: ProductDiscount) => {
    replaceRootBucket(
      update(bucket, {
        discount: {
          $set: discount
        }
      })
    );
  };

  const defaultOperator = new Operator('number', '>=');

  const addAggregates = () => {
    if (readOnly) return;
    const updatedBucket = update(bucket, {
      aggregateCondition: {
        conditions: {
          $push: [new FieldCondition('quantity', '', defaultOperator)]
        }
      }
    });

    setRootBucket(rootBucket.replace(updatedBucket));
  };

  const removeAggregate = (condition: BaseCondition) => {
    const newConditions = bucket.aggregateCondition.conditions.filter(
      x => x !== condition
    );

    const updatedBucket = update(bucket, {
      aggregateCondition: {
        conditions: {
          $set: newConditions
        }
      }
    });
    setRootBucket(rootBucket.replace(updatedBucket));
  };

  const disableAggregates = () => {
    const updatedBucket = update(bucket, {
      aggregateCondition: {
        conditions: {
          $set: []
        }
      }
    });
    setRootBucket(rootBucket.replace(updatedBucket));
  };

  const getInclusionCsv = () => {
    let inclusionSkusArray;
    bucket.condition.conditions.forEach(condition => {
      if (
        condition instanceof FieldCondition &&
        condition.fieldAccessor === 'sku' &&
        condition?.operator?.name === 'isIn'
      ) {
        inclusionSkusArray = condition?.value.map(
          (str: any, index: number) => ({
            sku: str
          })
        );
      }
    });
    return inclusionSkusArray;
  };

  const getExclusionCsv = () => {
    let exclusionSkusArray;
    bucket.condition.conditions.forEach(condition => {
      if (
        condition instanceof FieldCondition &&
        condition.fieldAccessor === 'sku' &&
        condition?.operator?.name === 'isNotIn'
      ) {
        exclusionSkusArray = condition?.value.map(
          (str: any, index: number) => ({
            sku: str
          })
        );
      }
    });
    return exclusionSkusArray;
  };

  const businessProductFilter = businessUnitContext.business.productFilter;
  useEffect(() => {
    const productFilter = _.mapValues(selectedProductFilterOptions, options =>
      _.map(options, o => o.value)
    );
    if (_.includes(_.keys(selectedProductFilterOptions), 'sku')) {
      setIsHierarchyBased(false);
    }
    _.each(
      businessProductFilter.hierarchyNew.concat(
        businessProductFilter.productFieldsNew
      ),
      item => {
        if (item) {
          productMasterService
            .filterProductFields(
              businessUnitContext.business,
              item.casaFieldName,
              productFilter
            )
            .then(response => {
              const productFieldOptionsList = asOptions(response.values);
              setProductFieldOptions(o => ({
                ...o,
                [response.productField]: productFieldOptionsList
              }));
              const allowedSelectedOptions = _.filter(
                productFieldOptionsList,
                o =>
                  _.some(
                    selectedProductFilterOptions[item.casaFieldName],
                    so => so.value === o.value
                  )
              );
              if (
                !_.isEqual(
                  allowedSelectedOptions,
                  selectedProductFilterOptions[item.casaFieldName]
                )
              ) {
                setSelectedProductFilterOptions(o => ({
                  ...o,
                  [item.casaFieldName]: allowedSelectedOptions
                }));
              }
            });
        }
      }
    );
  }, [businessUnitContext.business]);

  const labelValueFor = (val?: string) => ({ label: val, value: val });
  // const labelValueForSelect = (
  //   selectedOption: { label: string; value: string } | undefined,
  //   def = ''
  // ) => {
  //   if (selectedOption?.value)
  //     return { label: selectedOption?.label, value: selectedOption?.value };
  //   return { label: _.capitalize(def), value: def };
  //   // if (val) return { label: _.capitalize(val), value: val };
  //   // return { label: _.capitalize(def), value: def };
  // };

  const optionsFor = (val: any) =>
    Object.keys(val).map((key: string) => labelValueFor(val[key]));

  const getIsAddDisabled = () => {
    if (isExclusion) {
      if (isHierarchyBased) {
        return isEmpty(selectedExclusionProductFilterOptions.category) || isEmpty(selectedExclusionProductFilterOptions.subCategory);
      } else {
        return isEmpty(selectedExclusionProductFilterOptions.sku);
      }
    } else {
      if (isHierarchyBased) {
        return isEmpty(selectedProductFilterOptions.category) || isEmpty(selectedProductFilterOptions.subCategory);
      } else {
        return isEmpty(selectedProductFilterOptions.sku)
      }
    }
  }

  return (
    <div
      id="section"
      style={{
        width: '100%',
        padding: '20px 15px 20px 15px',
        marginBottom: 20,
        marginTop: 20,
        border: '1px solid #A8A8A880',
        borderRadius: 10,
        boxShadow: '0px 6px 10px #0000001a'
      }}
    >
      {bucket.isInclusionDefined ? (
        <div className={'d-flex flex-column position-relative'}>
          <span>Selected Product (s)</span>
          <div
            className={'col-9 d-flex align-items-center '}
            style={{ cursor: 'pointer' }}
          >
            <span className="skusListStyle">
              {_(bucket.condition.conditions)
                .map(c =>
                  c instanceof FieldCondition && _.some(c.value)
                    ? getFieldConditionPreviewText(c)
                    : undefined
                )
                .compact()
                .join(' > ')}
            </span>
            {getInclusionCsv() && (
              <CSVLink
                data={getInclusionCsv() || []}
                filename={'SKUS_LIST'}
                className={'downloadCsvStyle'}
                enclosingCharacter={``}
              >
                <GetAppIcon style={{ fontSize: 22 }} />
              </CSVLink>
            )}
          </div>
          <div
            className={'text-right position-absolute'}
            style={{ textAlign: 'center', right: '0' }}
          >
            <Button
              style={{ fontSize: 20 }}
              onClick={() => {
                setIsExclusion(false);
                setRootBucket(rootBucket.remove(bucket) as JoinBucket);
              }}
              disabled={readOnly}
            >
              x
            </Button>
          </div>
        </div>
      ) : (
        <div
          className={'d-flex justify-space-between position-relative'}
          style={{ width: '100%' }}
        >
          <div className={'d-flex flex-column justify-content-start'}>
            <Button
              style={{
                width: '220px',
                border: '1px solid #DEDEDE',
                borderRadius: 4,
                color: '#707070'
              }}
              variant="outlined"
              color="primary"
              onClick={() => setDialogOpen(c => !c)}
              disabled={readOnly}
            >
              Select Product(s)
            </Button>
          </div>
          <div
            className={'text-right position-absolute'}
            style={{ textAlign: 'right', right: '0' }}
          >
            <Button
              style={{ fontSize: 22 }}
              onClick={() => {
                setIsExclusion(false);
                setRootBucket(rootBucket.remove(bucket) as JoinBucket);
              }}
            >
              x
            </Button>
          </div>
        </div>
      )}

      {bucket.isExclusionDefined ? (
        <div>
          <div className={'d-flex flex-column'} style={{ marginTop: '20px' }}>
            <span>Excluded Product (s)</span>
            <div
              className={'col-9 d-flex align-items-center '}
              style={{ cursor: 'pointer' }}
            >
              <span className="skusListStyle">
                {_(bucket.condition.conditions)
                  .map(c =>
                    c instanceof FieldCondition && _.some(c.value)
                      ? getFieldConditionExclusionPreviewText(c)
                      : undefined
                  )
                  .compact()
                  .join(' > ')}
              </span>
              {getExclusionCsv() && (
                <CSVLink
                  data={getExclusionCsv() || []}
                  filename={'EXCLUSION_SKUS_LIST'}
                  className={'downloadCsvStyle'}
                  enclosingCharacter={``}
                >
                  <GetAppIcon style={{ fontSize: 22 }} />
                </CSVLink>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            className={'d-flex flex-column justify-content-start'}
            style={{ marginTop: '10px' }}
          >
            <Button
              style={{
                width: '220px',
                border: '1px solid #DEDEDE',
                borderRadius: 4,
                color: '#707070',
                marginRight: '20px'
              }}
              variant="outlined"
              color="primary"
              onClick={() => {
                setIsExclusion(true);
                setDialogOpen(c => !c);
              }}
              disabled={readOnly}
            >
              Select Exclusion Product(s)
            </Button>
          </div>
        </div>
      )}

      <hr color="#DEDEDE" style={{ height: 0 }} />
      <div className={'d-flex flex-column mt-1'}>
        <FamilyConfigurations
          bucket={bucket}
          readOnly={readOnly}
          replaceRootBucket={replaceRootBucket}
          productFieldOptions={productFieldOptions}
        />
        <div className={'d-flex align-items-center justify-content-between'}>
          <div className="d-flex flex-row align-items-center">
            <CustomCheckbox
              checked={bucket.aggregateCondition.conditions.length > 0}
              onChange={event =>
                event.target.checked ? addAggregates() : disableAggregates()
              }
              disabled={readOnly}
            />
            Enable Aggregate Conditions
          </div>
          {bucket.aggregateCondition.conditions.length > 0 && (
            <div>
              <Select
                className="aggregateSelect"
                value={labelValueFor(bucket.aggregateCondition.joinType)}
                options={optionsFor(LogicalOperators)}
                onChange={v => {
                  const optionValue = v as OptionType;
                  const value = optionValue.value as keyof typeof LogicalOperators;
                  const updatedBucket = update(bucket, {
                    aggregateCondition: {
                      joinType: {
                        $set: LogicalOperator[value]
                      }
                    }
                  });
                  setRootBucket(rootBucket.replace(updatedBucket));
                }}
                isDisabled={readOnly}
              />
            </div>
          )}
        </div>
        <div className="m-2">
          {bucket.aggregateCondition.conditions.map((baseCondition, index) => {
            const condition = baseCondition as FieldCondition;
            const { operator, value } = condition;
            let splitBetweenValue: any[] = [];
            if (operator?.name === 'BETWEEN') {
              splitBetweenValue = value.split('~');
            }

            return (
              <div className="m-2 p-3 aggregateContainer" key={index}>
                <div className="aggregateSection">
                  <div className="aggregateSectionContent">For</div>
                  <Select
                    className="aggregateSelect"
                    value={labelValueFor(condition.fieldAccessor)}
                    options={optionsFor(AggregateTypes)}
                    onChange={v => {
                      const optionValue = v as OptionType;
                      const value = optionValue.value as keyof typeof AggregateTypes;
                      const conditions = [
                        ...bucket.aggregateCondition.conditions
                      ];
                      const currentCondition = conditions[
                        index
                      ] as FieldCondition;
                      conditions[index] = new FieldCondition(
                        value,
                        currentCondition.value,
                        currentCondition.operator || defaultOperator
                      );

                      const updatedBucket = update(bucket, {
                        aggregateCondition: {
                          conditions: {
                            $set: conditions
                          }
                        }
                      });
                      setRootBucket(rootBucket.replace(updatedBucket));
                    }}
                    isDisabled={readOnly}
                  />
                  <div className="aggregateSectionContent">That is</div>
                  <Select
                    className="aggregateSelect"
                    value={labelValueFor(condition?.operator?.name)}
                    options={optionsFor(NumericalOperators)}
                    onChange={v => {
                      const optionValue = v as OptionType;
                      const value = optionValue.value as keyof typeof NumericalOperators;
                      const conditions = [
                        ...bucket.aggregateCondition.conditions
                      ];
                      const currentCondition = conditions[
                        index
                      ] as FieldCondition;
                      conditions[index] = new FieldCondition(
                        currentCondition.fieldAccessor || 'quantity',
                        currentCondition.value,
                        new Operator('number', value)
                      );

                      const updatedBucket = update(bucket, {
                        aggregateCondition: {
                          conditions: {
                            $set: conditions
                          }
                        }
                      });
                      setRootBucket(rootBucket.replace(updatedBucket));
                    }}
                    isDisabled={readOnly}
                  />
                  <div className="aggregateSectionContent">a value of</div>
                  {operator?.name !== 'BETWEEN' && (
                    // <TextField
                    //   className="aggregateText"
                    //   value={condition.value}
                    //   type="number"
                    //   onChange={event => {
                    //     const { value } = event.target;
                    <TextField
                      className="aggregateText"
                      value={condition.value}
                      type="number"
                      onChange={event => {
                        const { value } = event.target;
                        if (
                          !value ||
                          (value[value.length - 1].match('[0-9]') &&
                            value[0].match('[1-9]'))
                        ) {
                          const conditions = [
                            ...bucket.aggregateCondition.conditions
                          ];
                          const currentCondition = conditions[
                            index
                          ] as FieldCondition;
                          conditions[index] = new FieldCondition(
                            currentCondition.fieldAccessor || 'quantity',
                            value,
                            currentCondition.operator || defaultOperator
                          );

                          const updatedBucket = update(bucket, {
                            aggregateCondition: {
                              conditions: {
                                $set: conditions
                              }
                            }
                          });
                          setRootBucket(rootBucket.replace(updatedBucket));
                        }
                      }}
                      disabled={readOnly}
                      defaultValue={1}
                    />
                  )}
                  {operator?.name === 'BETWEEN' && (
                    <>
                      <span className="aggregateSectionContent">From</span>
                      {readOnly ? (
                        <TextField
                          className="aggregateText"
                          type="number"
                          value={splitBetweenValue[0]}
                          name={'from'}
                          style={{ width: 70 }}
                          onChange={eve => {
                            setFromBetweenValue(eve.target.value);
                          }}
                        />
                      ) : (
                        <TextField
                          className="aggregateText"
                          type="number"
                          name={'from'}
                          style={{ width: 70 }}
                          onChange={eve => {
                            setFromBetweenValue(eve.target.value);
                          }}
                        />
                      )}
                      <span className="aggregateSectionContent">To</span>
                      {readOnly ? (
                        <TextField
                          className="aggregateText"
                          type="number"
                          name={'to'}
                          style={{ width: 70 }}
                          value={splitBetweenValue[1]}
                          onChange={eve => {
                            const value = `${fromBetweenValue}~${eve.target.value}`;
                            const conditions = [
                              ...bucket.aggregateCondition.conditions
                            ];

                            const currentCondition = conditions[
                              index
                            ] as FieldCondition;
                            conditions[index] = new FieldCondition(
                              currentCondition.fieldAccessor || 'quantity',
                              value,
                              currentCondition.operator || defaultOperator
                            );

                            const updatedBucket = update(bucket, {
                              aggregateCondition: {
                                conditions: {
                                  $set: conditions
                                }
                              }
                            });

                            setRootBucket(rootBucket.replace(updatedBucket));
                          }}
                        />
                      ) : (
                        <TextField
                          className="aggregateText"
                          type="number"
                          name={'to'}
                          style={
                            betweenErrorMessage &&
                            index ===
                              bucket?.aggregateCondition?.conditions?.length - 1
                              ? { width: 103, marginTop: 40 }
                              : { width: 103 }
                          }
                          helperText={
                            betweenErrorMessage &&
                            index ===
                              bucket?.aggregateCondition?.conditions?.length - 1
                              ? "Should be more than 'from' "
                              : ''
                          }
                          onChange={eve => {
                            Number(fromBetweenValue) > Number(eve.target.value)
                              ? setBetweenErrorMessage(true)
                              : setBetweenErrorMessage(false);

                            const value = `${fromBetweenValue}~${eve.target.value}`;
                            const conditions = [
                              ...bucket.aggregateCondition.conditions
                            ];

                            const currentCondition = conditions[
                              index
                            ] as FieldCondition;
                            conditions[index] = new FieldCondition(
                              currentCondition.fieldAccessor || 'quantity',
                              value,
                              currentCondition.operator || defaultOperator
                            );

                            const updatedBucket = update(bucket, {
                              aggregateCondition: {
                                conditions: {
                                  $set: conditions
                                }
                              }
                            });

                            setRootBucket(rootBucket.replace(updatedBucket));
                          }}
                        />
                      )}
                    </>
                  )}
                  {!readOnly && (
                    <div
                      className="crossContainer"
                      onClick={() => removeAggregate(condition)}
                    >
                      <img className={'addFilterIcon'} src={Cross} alt="" />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {!readOnly && (
        <div className="addFilterContainer" onClick={addAggregates}>
          <img className={'addFilterIcon'} src={AddFilter} alt="" />
          <div className="addFilterContent">{'add filter'}</div>
        </div>
      )}
      <hr color="#DEDEDE" style={{ height: 0 }} />
      <Discount
        discount={bucket.discount}
        readOnly={readOnly}
        updateDiscount={updateDiscount}
        productFieldOptions={productFieldOptions}
      />
      <Dialog
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isDialogOpen}
        maxWidth="lg"
        scroll="paper"
      >
        <DialogTitle id="alert-dialog-xide-title" className={'text-center'}>
          Select Products
        </DialogTitle>
        <DialogContent
          style={{
            width: `${1000}px`,
            minWidth: 300,
            minHeight: 300,
            paddingBottom: 30
          }}
        >
          <div>
            <RadioGroup
              aria-label="selection type"
              name="selectionType"
              value={isHierarchyBased}
              onChange={toggleSelectionType}
            >
              <FormControlLabel
                value={true}
                control={<Radio disabled={readOnly} />}
                label="Select product(s) by hierarchy"
              />
            </RadioGroup>
          </div>
          <SelectProductHierarchy
            isExclusion={isExclusion}
            selectedExclusionProductFilterOptions={
              selectedExclusionProductFilterOptions
            }
            setSelectedExclusionProductFilterOptions={
              setSelectedExclusionProductFilterOptions
            }
            productFilter={businessProductFilter}
            selectedProductFilterOptions={selectedProductFilterOptions}
            setSelectedProductFilterOptions={setSelectedProductFilterOptions}
            isHierarchyBased={isHierarchyBased}
            productFieldOptions={productFieldOptions}
          />
          <div className="clearfix d-flex row my-3">
            <div className={'col-5'}>
              <hr />
            </div>
            <div className={'col-2 text-center'}>OR</div>
            <div className={'col-5'}>
              <hr />
            </div>
          </div>
          <div className="">
            <RadioGroup
              aria-label="selection type"
              name="skuSelectionType"
              value={isHierarchyBased}
              onChange={toggleSelectionType}
            >
              <FormControlLabel
                value={false}
                control={<Radio disabled={readOnly} />}
                label="Select product(s) using sku"
              />
            </RadioGroup>
          </div>
          <SelectProductSkus
            isExclusion={isExclusion}
            disabled={isHierarchyBased}
            setSelectedProductFilterOptions={setSelectedProductFilterOptions}
            setSelectedExclusionProductFilterOptions={
              setSelectedExclusionProductFilterOptions
            }
          />
        </DialogContent>
        <DialogActions className={'mb-3'}>
          <Button
            onClick={() => {
              setIsExclusion(false);
              setDialogOpen(c => !c);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <div className={'mr-3'}>&nbsp;</div>
          <div className={'pr-3'}>
            <Button
              disabled={getIsAddDisabled()}
              onClick={() => {
                const productBucket = bucket as ProductBucket;
                const filedConditions = _(
                  isExclusion
                    ? selectedExclusionProductFilterOptions
                    : selectedProductFilterOptions
                )
                  .entries()
                  .filter(([fieldName, fieldNameOptions]) =>
                    _.some(fieldNameOptions)
                  )
                  .map(([fieldName, fieldNameOptions]) => {
                    return new FieldCondition(
                      fieldName,
                      _.map(fieldNameOptions, 'value'),
                      isExclusion
                        ? Operator.isNotInOperator
                        : Operator.isInOperator
                    );
                  })
                  .value();
                filedConditions.forEach(condition =>
                  productBucket.condition.conditions.push(condition)
                );
                setRootBucket(rootBucket.replace(productBucket));
                setDialogOpen(c => !c);
                setIsExclusion(false);
              }}
              variant="contained"
              color="primary"
              className={'mr-3'}
            >
              Add
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};
