import { SectionType } from '../../DynamicInvoiceGenerator/InvoiceGenerator';
import type Section from '../../DynamicInvoiceGenerator/models/Section';
import SectionField from '../../DynamicInvoiceGenerator/models/SectionField';

export type SectionConfig = { [key: string]: Section }
const sectionConfig: SectionConfig = {
  [SectionType.TENANT_INFO]: {
    heading: "Business Details",
    fields: [
      {
        id :"tenantName",
        type: "text",
        label: "",
        getter: "",
        value: "Celekt",
        req: true
      },
      {
        id : "bill.storeAddress",
        type: "text",
        label: "addrss",
        getter: "bill.storeAddress",
        value: "",
        req: true
      },
      {
        id: "bill.gstin",
        getter: "bill.gstin",
        type: "text",
        label: "gstin",
        value: "",
        req: true
      },
      {
        id: "logo",
        getter: "",
        type: "image",
        label: "",
        value: "",
        req: false
      }
    ],
    html: ""
  },
  [SectionType.CUSTOMER_INFO]: {
    heading: "Customer Details",
    fields: [
      {
        id: "0",
        label: "Name",
        getter: "name",
        type: "text",
        value: "",
        req: true
      },
      {
        id: "1",
        label: "Mobile No.",
        getter: "contact.mobile",
        type: "text",
        req: true,
        value: ""
      },
      {
        id: "2",
        label: "Email Id",
        getter: "contact.email",
        type: "text",
        value: "",
        req: true
      }
    ],
    html: ""
  },
  [SectionType.ORDER_INFO]: {
    heading: "Tax Invoice",
    html: "",
    fields: [
      {
        id : "0",
        label: "Invoice No.",
        getter: "bill.billNo",
        type: "text",
        value: "",
        req: true
      },
      {
        id : "1",
        type: "text",
        label: "Invoice Date",
        getter: "bill.date",
        value: "",
        req: true
      },
      {
        id : "2",
        type: "currency",
        label: "Total Amount",
        getter: "bill.totalAmount",
        value: "",
        req: true
      }
    ]
  },
  [SectionType.ORDER_LIST]: {
    heading: "order summary",
    fields: [
      {
        id : "name", 
        label: "Item name",
        getter: "name",
        type: "text",
        value: "1",
        req: true
      },
      {
        id: "quantity",
        label: "Qty",
        getter: "quantity",
        type: "text",
        value: "2",
        req: true
      },
      {
        id:"5",
        label: "total",
        getter: "total",
        type: "text",
        value: "100",
        req: true
      }
    ],
    html: ""
  },
  [SectionType.ORDER_SUMMARY]: {
    heading: "Order Summary",
    html: "",
    fields: [
      {
        id : "0",
        type: "computed",
        label: "Total MRP Value",
        getter: "total",
        value: "",
        req: true
      },
      {
        id : "1",
        type: "computed",
        label: "Total Discount",
        getter: "discount",
        value: "",
        req: true
      },
      {
        id : "2",
        type: "computed",
        label: "Net Payable",
        getter: "totalOrderValue",
        value: "",
        req: true
      },
      {
        id : "3",
        type: "computed",
        label: "Tax Amount",
        getter: "tax",
        value: "",
        req: true
      }
    ]
  },
  [SectionType.HSN_SUMMARY]: {
    heading: "HsnSummary",
    html: "",
    fields: [
      {
        id : "0",
        type: "computed",
        label: "GST %",
        getter: "GSTPercent",
        value: "",
        req: true
      },
      {
        id : "1",
        type: "computed",
        label: "Taxable",
        getter: "productTax",
        value: "",
        req: true
      },
      {
        id : "2",
        type: "computed",
        label: "CGST",
        getter: "productCGST",
        value: "",
        req: true
      },
      {
        id : "3",
        type: "computed",
        label: "SGST",
        getter: "productSGST",
        value: "",
        req: true
      },
      {
        id : "4",
        type: "computed",
        label: "IGST",
        getter: "productIGST",
        value: "",
        req: true
      },
      {
        id : "5",
        type: "computed",
        label: "CESS",
        getter: "productCESS",
        value: "",
        req: true
      }
    ]
  },
  [SectionType.MISC_INFO]: {
    heading: "Terms and Condition",
    fields: [
      {
        id : "1",
        label: "Terms and conditions",
        value: "<ol><li>Exchange within 15 days only</li><li>Without Tag Exchange not Accepted</li><li>No Cash Refund</li><li>No Exchange or Return in Sale Period</li><li>Worn Goods will not ve Entertained</li><li>MRP are inclusive of Applicable Tax</li><ol>",
        getter: "",
        type: "html_content",
        req:true
      },
      {
        id : "2",
        label: "label to click on",
        getter: "https://www.yoru-site.com/",
        value: "visit us at",
        type: "link",
        req:true
      }
    ],
    html: ""
  },
  [SectionType.PREVIEW]: {
    heading: "Preview",
    fields: [],
    html: ""
  }
};

export type AdditionalConfigFields = { [SectionType: string]: SectionField[] }
const additionalConfigFields: AdditionalConfigFields = {
  [SectionType.ORDER_LIST]: [
    {
      id :  "hsnSacCode",
      label: "HSN Code",
      getter: "hsnSacCode",
      type: "text",
      value: "",
      req: false
    },
    {
      id:"productDiscount",
      type: "computed",
      label: "Dis. Amt.",
      getter: "productDiscount",
      value: "",
      req: false
    },
    {
      id:"price",
      type: "currency",
      label: "MRP",
      getter: "price",
      getterOptions: ['price'],
      selectedGetterOption : 0,
      value: "",
      req: true
    },
    {
      id:"productTaxableAmt",
      label: "Taxable Amount",
      getter: "productTaxableAmt",
      type: "computed",
      value: "",
      req: false
    },
    {
      id:"productTax",
      label: "GST",
      getter: "productTax",
      type: "computed",
      value: "",
      req: false
    }
  ]
}
export default { sectionConfig, additionalConfigFields };
