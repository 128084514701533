/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import './index.css';
import {
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import personDark from '../../../../Assets/Offers/personDark.svg';
import CustomCheckbox from '../../../CheckBox';
import {
  OfferType,
  OfferDefinitionClasses,
  OperatorTypes
} from '../../../../../constants';
import update from 'immutability-helper';
import { ReadOnlyContext } from '../../../../OfferDetail/ReadOnlyContext';
import Button from '@material-ui/core/Button';
import RowCriteriaContext from '../../../../OfferDetail/RowCriteriaContext';
import OfferUtils from '../../../../../utils/OfferUtils';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  required: {
    '&:after': {
      content: `' *'`,
      color: theme.palette.primary.main
    }
  }
}));

export default function BillValueTab(props) {
  const {
    data,
    billAggregateCondition,
    setDefinition,
    billDiscount,
    setDiscountDefinition,
    offer,
    setOffer
  } = props;
  const { readOnly } = useContext(ReadOnlyContext);
  const { rows, setRows } = useContext(RowCriteriaContext);
  const { eligibility, changeHandler, loyaltyVendorInfo } = data;
  const classes = useStyles();

  const validateLastRow = () => {
    const rowLength = rows.length - 1;
    if (rowLength < 0) {
      return true;
    }
    const values = [...rows];
    const isValid = OfferUtils.validateConditionsAndSetErrors(
      rowLength,
      rowLength,
      values,
      billDiscount.discountType
    );
    setRows(values);
    return isValid;
  };
  const handleAddRow = () => {
    const isValid = validateLastRow();
    if (!isValid) {
      return;
    }
    const item = {
      from: (parseInt(rows[rows.length - 1]?.to) + 1).toString() || '',
      to: '',
      value: '',
      maxDiscount: '',
      key: rows.length
    };
    setRows([...rows, item]);
  };
  const removeRow = item => {
    const values = [...rows];
    values.splice(item, 1);
    values.map((item, idx) => (item.key = idx));
    setRows(values);
  };
  const changeRow = (value, index, type) => {
    const allRows = [...rows];
    allRows[index][type] = value;

    if (allRows[index].error) {
      allRows[index]['error'][type] = false;
    }
    setRows(allRows);
  };
  const addBillValueCondition = () => {
    billAggregateCondition.push({
      className: OfferDefinitionClasses.FIELD_CONDITION,
      fieldAccessor: 'billAmount',
      operator: {
        className: OfferDefinitionClasses.OPERATOR,
        name: null,
        operatorType: OperatorTypes.NUMBER
      },
      value: null
    });
    setDefinition(billAggregateCondition);
    changeHandler('eligibility', OfferType.BILL_VALUE);
    setDiscountDefinition(
      update(billDiscount, { offerType: { $set: OfferType.BILL_VALUE } })
    );
  };

  const removeBillValueCondition = () => {
    _.remove(billAggregateCondition, condition => {
      return condition.fieldAccessor === 'billAmount';
    });
    changeHandler('eligibility', null);
    setDiscountDefinition(update(billDiscount, { offerType: { $set: '' } }));
  };

  const handleProductOffer = (event, name) => {
    loyaltyVendorInfo[name] = event.target.checked;
  };

  const handleOffers = event => {
    setOffer(event.target.checked);
    if (!event.target.checked) {
      loyaltyVendorInfo.allowRedemptionOnPromoItem = false;
      loyaltyVendorInfo.ginesysProductOffer = false;
    }
  };

  useEffect(() => {
    setOffer(
      loyaltyVendorInfo.allowRedemptionOnPromoItem ||
        loyaltyVendorInfo.ginesysProductOffer
    );
  }, [loyaltyVendorInfo]);

  return (
    <div>
      <div>
        <CustomCheckbox
          onChange={handleOffers}
          disabled={readOnly}
          defaultChecked={
            offer ||
            loyaltyVendorInfo.allowRedemptionOnPromoItem ||
            loyaltyVendorInfo.ginesysProductOffer
          }
        />
        <span>Is Ginesys Offer</span>
      </div>
      {offer && (
        <div style={{ marginLeft: 65 }}>
          <div>
            <CustomCheckbox
              name="allowRedemptionOnPromoItem"
              defaultChecked={loyaltyVendorInfo.allowRedemptionOnPromoItem}
              onChange={event =>
                handleProductOffer(event, 'allowRedemptionOnPromoItem')
              }
              disabled={readOnly}
            />
            <span>Apply Offer on Promo Items</span>
          </div>
          <div>
            <CustomCheckbox
              name="ginesysProductOffer"
              defaultChecked={loyaltyVendorInfo.ginesysProductOffer}
              onChange={event =>
                handleProductOffer(event, 'ginesysProductOffer')
              }
              disabled={readOnly}
            />
            <span>Apply Offer on Assorted Items</span>
          </div>
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CustomCheckbox
          checked={eligibility === 'BILL_VALUE'}
          onChange={event =>
            event.target.checked
              ? addBillValueCondition()
              : removeBillValueCondition()
          }
          disabled={readOnly}
        />
        <img src={personDark} alt="person Icon" />
        <span className="checkboxLabel">
          Set bill value eligibility for offer
        </span>
      </div>
      <div className={eligibility !== 'BILL_VALUE' && 'disabled'}>
        <RadioGroup
          style={{ marginLeft: 75, marginBottom: 50, display: 'block' }}
        >
          <FormControlLabel
            value="fixed"
            control={
              <Radio
                color="primary"
                checked={billDiscount.discountType === 'fixed'}
                onChange={event => {
                  const val = event.target.value;
                  if (val) {
                    setDiscountDefinition(
                      update(billDiscount, {
                        discountType: { $set: 'fixed' },
                        value: { $set: '0' },
                        maxDiscount: { $set: '0' }
                      })
                    );
                  }
                }}
                disabled={readOnly}
              />
            }
            label={
              <div className="radioLabel">
                <span style={{ marginRight: 10 }}>Fixed Value</span>
              </div>
            }
          />
          <FormControlLabel
            value="percentage"
            control={
              <Radio
                color="primary"
                checked={billDiscount.discountType === 'percent'}
                onChange={event => {
                  const val = event.target.value;
                  if (val) {
                    setDiscountDefinition(
                      update(billDiscount, {
                        discountType: { $set: 'percent' },
                        value: { $set: '0' },
                        maxDiscount: { $set: '0' }
                      })
                    );
                  }
                }}
                disabled={readOnly}
              />
            }
            label={
              <div className="radioLabel">
                <span style={{ marginRight: 10 }}>Percentage</span>
              </div>
            }
          />
        </RadioGroup>

        <table style={{ marginLeft: 10 }} className="billValueTableStyle">
          <thead>
            <tr>
              <th>
                <span className={classes.required}>From (in INR)</span>
              </th>
              <th>
                <span>To (in INR)</span>
              </th>
              <th>
                <span className={classes.required}>
                  {billDiscount.discountType === 'percent'
                    ? 'Offer Percentage(in percentage)'
                    : 'Offer Amount (in INR)'}
                </span>
              </th>
              {billDiscount.discountType === 'percent' && (
                <th>
                  <span>Upto (in INR)</span>
                </th>
              )}
              <th style={{ width: '200px' }}>
                <Button
                  color="primary"
                  variant="outlined"
                  className="fR mR10"
                  onClick={handleAddRow}
                  disabled={readOnly}
                  startIcon={<AddIcon />}
                >
                  Add Condition
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((item, idx) => (
              // eslint-disable-next-line react/jsx-key
              <tr>
                <td>
                  <TextField
                    type="number"
                    error={item.error && item.error.from}
                    helperText={
                      item.error && item.error.from
                        ? item.from === ''
                          ? 'Enter value'
                          : 'check previous "from" or "to" value'
                        : ''
                    }
                    placeholder="Enter From"
                    disabled={readOnly}
                    onInput={e => {
                      e.target.value = Math.max(0, parseInt(e.target.value));
                    }}
                    onChange={e => changeRow(e.target.value, idx, 'from')}
                    value={
                      (rows[idx - 1]?.to && parseInt(rows[idx - 1]?.to) + 1) ||
                      item.from ||
                      ''
                    }
                  />
                </td>
                <td>
                  <TextField
                    type="number"
                    error={item.error && item.error.to}
                    helperText={
                      item.error && item.error.to
                        ? item.to === ''
                          ? rows.length - 1 !== idx
                            ? 'Enter value'
                            : ''
                          : 'More than "from" value or check previous "to" value'
                        : ''
                    }
                    placeholder="Enter To"
                    disabled={readOnly}
                    onInput={e => {
                      e.target.value = Math.max(0, parseInt(e.target.value));
                    }}
                    value={item.to || ''}
                    onChange={e => changeRow(e.target.value, idx, 'to')}
                  />
                </td>
                <td>
                  <TextField
                    required
                    type="number"
                    error={item.error && item.error.value}
                    helperText={
                      item.error && item.error.value
                        ? item.value === ''
                          ? 'Enter value'
                          : 'less than To value'
                        : billDiscount.discountType === 'percent' &&
                          item?.value > 100
                        ? 'Less than 100'
                        : ''
                    }
                    placeholder="Enter Discount"
                    disabled={readOnly}
                    value={item?.value || ''}
                    onChange={e => changeRow(e.target.value, idx, 'value')}
                  />
                </td>
                {billDiscount.discountType === 'percent' && (
                  <td>
                    <TextField
                      type="number"
                      error={item.error && item.error.maxDiscount}
                      helperText={
                        item.error && item.error.maxDiscount
                          ? item.value === ''
                            ? 'Enter value'
                            : 'less than To value'
                          : ''
                      }
                      onInput={e => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 8);
                      }}
                      placeholder="Enter Max Price"
                      disabled={readOnly}
                      value={item.maxDiscount || ''}
                      onChange={e =>
                        changeRow(e.target.value, idx, 'maxDiscount')
                      }
                    />
                  </td>
                )}

                <td style={{ width: '200px' }}>
                  <span style={{ display: 'inline-flex' }}>
                    <div className={'col-3 text-right'}>
                      {rows.length > 1 && (
                        <Button
                          disabled={readOnly}
                          onClick={() => removeRow(idx)}
                        >
                          X
                        </Button>
                      )}
                    </div>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

BillValueTab.propTypes = {
  data: PropTypes.shape({
    discountOn: PropTypes.bool.isRequired,
    changeHandler: PropTypes.func.isRequired,
    eligibility: PropTypes.bool.isRequired
  }).isRequired,
  billAggregateCondition: PropTypes.arrayOf(PropTypes.object),
  setDefinition: PropTypes.func.isRequired
};

BillValueTab.defaultProps = {
  billConditions: []
};
