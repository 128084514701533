import * as yup from 'yup';

export const EditUserValidation = () => {
  const messages = {
    storeNameAlias: ['Store Name is Required'],
    mobile: ['Store Contact is Required'],
    store: ['Store Code is Required']
  };

  return yup.object().shape({
    storeNameAlias: yup
      .string()
      .trim()
      .required(messages.storeNameAlias[0]),

    mobile: yup
      .string()
      .required(messages.mobile[0])
      .trim(),
    store: yup
      .string()
      .trim()
      .required(messages.store[0])
  });
};

export const createUserValidation = () => {
  const messages = {
    storeNameAlias: ['Store Name Alias is Required'],
    mobile: ['Store Contact is Required', 'Only number field'],
    store: ['Store Code is Required']
  };

  return yup.object().shape({
    storeNameAlias: yup
      .string()
      .trim()
      .required(messages.storeNameAlias[0]),

    mobile: yup
      .string()
      .required(messages.mobile[0])

      .trim(),
    store: yup
      .string()
      .trim()
      .required(messages.store[0])
  });
};
