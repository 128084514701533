/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Modal,
  Grid,
  FormControlLabel,
  withStyles,
  Switch
} from '@material-ui/core';
import './index.scss';
import CloseIcon from '@material-ui/icons/Close';
import { TextFieldComponent } from '../../reusable/TextFieldComponent';
import DropDownField from '../../reusable/DropDownField';
import { useFormik } from 'formik';
import ButtonField from '../../reusable/ButtonField';
import ConfigService from '../../services/config.service';
import { useDispatch } from 'react-redux';
import { toastMessageAction } from '../../store/action';
import Backdrop from '@material-ui/core/Backdrop';
import { dataFormator, getPriorityList } from './formatData';
import _, { get, pick } from 'lodash';
import ContentMode from './ContentMode';

class AmeyoWhatsAppMsg {
  getValues(object, data) {
    return {
      authkey: object?.params?.namespace,
      sender: object?.params?.sender,
      priorityOrder: data?.priorityOrder,
      showCampaignNameInReport: data?.showCampaignNameInReport || false
    };
  }
}

class KarixWhatsAppSms {
  getValues(object, data) {
    return {
      ...object?.params,
      priorityOrder: data?.priorityOrder,
      showCampaignNameInReport: data?.showCampaignNameInReport || false
    };
  }
}

class WatiWhatsAppSms {
  getValues(object, data) {
    return {
      authkey: object?.params?.key,
      sender: object?.params?.send,
      priorityOrder: data?.priorityOrder,
      showCampaignNameInReport: data?.showCampaignNameInReport || false
    };
  }
}

class IcsWhatsApp {
  getValues(object, data) {
    return {
      priorityOrder: data?.priorityOrder,
      user: object?.params?.user,
      pass: object?.params?.pass,
      from: object?.params?.from,
      showCampaignNameInReport: data?.showCampaignNameInReport || false
    };
  }
}

class IcsRcs {
  getValues(object, data) {
    return {
      priorityOrder: data?.priorityOrder,
      user: object?.params?.user,
      pass: object?.params?.pass,
      showCampaignNameInReport: data?.showCampaignNameInReport || false
    };
  }
}

class IcsSms {
  getValues(object, data) {
    let contentModeParams = {};
    Object.values(ContentMode).forEach(contentMode => {
      const contentParams = pick(
        get(object.contentModeParams, contentMode, {}),
        ['from', 'user', 'pass']
      );
      contentModeParams = {
        ...contentModeParams,
        ...Object.entries(contentParams).reduce((acc, [key, value]) => {
          return {
            ...acc,
            [`${key}_${contentMode}`]: value
          };
        }, {})
      };
    });
    return {
      ...pick(object.params, [
        'priorityOrder',
        'showCampaignNameInReport',
        'peId'
      ]),
      ...contentModeParams,
      showCampaignNameInReport: data?.showCampaignNameInReport || false
    };
  }
}

class Msg91Email {
  getValues(object, data) {
    return {
      authkey: object?.params?.authkey,
      domain: object?.params?.domain,
      fromName: object?.params?.fromName,
      sender: object?.senderEmail,
      priorityOrder: data?.priorityOrder,
      showCampaignNameInReport: data?.showCampaignNameInReport || false
    };
  }
}

class SesSendEmail {
  getValues(object, data) {
    return {
      awsAccessKeyId: object?.awsAccessKeyId,
      awsSecretAccessKey: object?.awsSecretAccessKey,
      awsRegion: object?.awsRegion,
      sender: object?.senderEmail,
      priorityOrder: data?.priorityOrder,
      showCampaignNameInReport: data?.showCampaignNameInReport || false
    };
  }
}

const getBaseValues = (object, data) => {
  return {
    sender: object?.params?.sender,
    promotionalSender: object?.params?.promotionalSender,
    priorityOrder: data?.priorityOrder
  };
};
class OneXtelSendSms {
  getValues(object, data) {
    return {
      authkey: object?.params?.authkey,
      entityId: object?.params?.entityId,
      ...getBaseValues(object, data),
      authKeyServiceExplicit: data?.authKeyServiceExplicit,
      authKeyPromotional: data?.authKeyPromotional,
      showCampaignNameInReport: data?.showCampaignNameInReport || false
    };
  }
}

class STMSendSms {
  getValues(object, data) {
    return {
      authkey: object?.params?.authkey,
      clientId: object?.params?.clientId,
      principleEntityId: object?.params?.principleEntityId,
      ...getBaseValues(object, data),
      showCampaignNameInReport: data?.showCampaignNameInReport || false
    };
  }
}

class ValueFirstSendSms {
  getValues(object, data) {
    return {
      username: object?.params?.username,
      password: object?.params?.password,
      ...getBaseValues(object, data),
      showCampaignNameInReport: data?.showCampaignNameInReport || false
    };
  }
}

class Msg91SendSms {
  getValues(object, data) {
    return {
      authkey: object?.params?.authkey,
      ...getBaseValues(object, data),
      showCampaignNameInReport: data?.showCampaignNameInReport || false
    };
  }
}

class KarixSendV2 {
  getValues(object, data) {
    return {
      authKeyPromotional: _.get(object, 'params.authKeyPromotional', ''),
      authKeyTransactional: _.get(object, 'params.authKeyTransactional', ''),
      authKeyOtp: _.get(object, 'params.authKeyOtp', ''),
      otpSender: _.get(object, 'params.otpSender', ''),
      dltEntityId: _.get(object, 'params.dltEntityId', ''),
      from: _.get(object, 'params.from', ''),
      ...getBaseValues(object, data),
      showCampaignNameInReport: data?.showCampaignNameInReport || false
    };
  }
}

class KarixEmail {
  getValues(object, data) {
    return {
      authkey: object?.params?.authkey,
      ...getBaseValues(object, data),
      showCampaignNameInReport: data?.showCampaignNameInReport || false,
      fromName: object?.params?.fromName,
      fromEmail: object?.params?.fromEmail,
      userName: object?.params?.userName,
      password: object?.params?.password
    };
  }
}

class WonderMail {
  getValues(object, data) {
    return {
      authkey: object?.params?.['x-api-key'],
      ...getBaseValues(object, data),
      showCampaignNameInReport: data?.showCampaignNameInReport || false,
      fromName: object?.params?.fromName,
      fromEmail: object?.params?.fromEmail
    };
  }
}

const provider = {
  AmeyoWhatsAppMsg,
  KarixWhatsAppSms,
  Msg91Email,
  SesSendEmail,
  OneXtelSendSms,
  STMSendSms,
  ValueFirstSendSms,
  Msg91SendSms,
  KarixSendV2,
  KarixEmail,
  WonderMail,
  WatiWhatsAppSms,
  IcsWhatsApp,
  IcsSms,
  IcsRcs
};
const providerList = Object.keys(provider);

export const CustomSwitch = withStyles({
  switchBase: {
    color: '#f4f4f4',
    '&$checked': {
      color: '#F58974'
    },
    '&$checked + $track': {
      backgroundColor: '#f5aa9b'
    }
  },
  checked: {},
  track: {}
})(Switch);

export const boxStyle = {
  position: 'absolute',
  top: '47%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  outline: 'none',
  boxShadow: 24
};

export default function ConfigModal(props) {
  const {
    imgSrc,
    style,
    spanStyle,
    version,
    getVersion,
    handlers,
    priorityName,
    campaignCheck,
    inputFields,
    initialState,
    fieldsToSchema,
    createStatus,
    setCreateStatus,
    business,
    disabled,
    width,
    gridSpace,
    label,
    user,
    reset,
    contentMode
  } = props;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [handlerConfig, setHandlerConfig] = useState();

  const { tenant } = user;
  const [validation, setValidation] = useState();

  // const deliveryModes = ['transactional', 'promotional', 'OTP'];
  // const getDefaultPriorityByDeliveryModes = priority =>
  //   deliveryModes.reduce((acc, f) => ({ ...acc, [f]: priority || 1 }), {});

  const onSubmit = (data, { resetForm }) => {
    Object.keys(data).forEach(key => {
      if (data[key] === '') {
        delete data[key];
      }
    });
    let handleData = {};
    handleData = dataFormator(priorityName, data);
    const workData = {
      version: version,
      sender: data.sender,
      promotionalSender: data.promotionalSender,
      handlers: [
        {
          ...handleData,
          priorityOrderByMode: {
            ..._.get(handlerConfig, 'priorityOrderByMode'),
            [contentMode]: handleData.priorityOrder
          }
        }
      ],
      tenant: tenant.esKey,
      businessUnit: business?.name,
      deliveryMode: contentMode
    };

    ConfigService.saveChannelsConfig(workData).then(() => {
      setCreateStatus(!createStatus);
      dispatch(
        toastMessageAction({
          severity: 'success',
          message: `${version} configuration added successfully!`,
          messageStatus: true
        })
      );
      resetForm();
      setOpen(false);
    });
  };

  //   to handle formik data
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validation,
    onSubmit
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    setValues,
    resetForm
  } = formik;

  useEffect(() => {
    setValidation(fieldsToSchema);
  }, [priorityName, createStatus, fieldsToSchema]);

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const parseString = fields => {
    try {
      return JSON.parse(fields.params);
    } catch {
      return {};
    }
  };

  useEffect(() => {
    let fields = {};
    if (handlers && handlers.length) {
      const fieldData = handlers.find(item => {
        if (
          item?.name === priorityName &&
          getVersion === version &&
          business.name === item.businessUnit
        ) {
          return item;
        }
        return null;
      });
      setHandlerConfig(fieldData);
      fields = fieldData || {};
    }
    if (
      handlers &&
      handlers.length &&
      Object.prototype.hasOwnProperty.call(fields, 'params')
    ) {
      if (providerList.includes(priorityName)) {
        const result = new provider[priorityName]();
        const fieldParams = parseString(fields);
        let fieldsValues = result.getValues(fieldParams, fields);
        console.log('contentMode', contentMode);
        if (fields.name === 'OneXtelSendSms') {
          fieldsValues = {
            ...fieldsValues,
            authKeyServiceExplicit: fieldParams.params.authKeyServiceExplicit,
            authKeyPromotional: fieldParams.params.authKeyPromotional
          };
        }
        setValues({
          ...fieldsValues,
          priorityOrder: _.get(
            handlerConfig,
            `priorityOrderByMode.${contentMode}`,
            0
          )
        });
      }
    }
    if (business.name !== fields.businessUnit) {
      resetForm();
    }
  }, [
    business.name,
    getVersion,
    handlers,
    priorityName,
    resetForm,
    setValues,
    version,
    handlerConfig,
    contentMode
  ]);

  return (
    <div
      style={{
        ...width,
        border: '1px solid rgba(225, 225, 225, 1)',
        padding: '0.4rem 0.5rem',
        borderRadius: '0.4rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
      }}
      className="channelHolder"
    >
      <div
        className={`imagePriority${inputFields.length ? '' : ' disabled'}`}
        onClick={() => setOpen(!disabled)}
        style={{ opacity: disabled === 0 ? 0.7 : 1 }}
      >
        <img
          src={imgSrc}
          alt=""
          className="configurationImage"
          style={{
            ...style,
            maxWidth: '10.25rem',
            maxHeight: '3.6rem',
            overflow: 'hidden'
          }}
        />
        {_.get(handlerConfig, `priorityOrderByMode.${contentMode}`, 0) > 0 && (
          <span className="priorityOrderStyle" style={spanStyle}>
            {values.priorityOrder}
          </span>
        )}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000
        }}
      >
        <Box
          sx={boxStyle}
          style={{
            padding: '20px',
            width: gridSpace === 12 ? '' : '700px',
            paddingLeft: gridSpace === 12 ? '30px' : '20px'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
          </div>
          <div className="modalContainer">
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h5"
              className="modalTitle"
              style={{ marginBottom: '20px' }}
            >
              {label}
            </Typography>
            <form onSubmit={formik.handleSubmit} className="formContainer">
              <Grid container spacing={3}>
                {inputFields.map((field, i) => {
                  if (field.name === 'priorityOrder') {
                    return (
                      <Grid item xs={gridSpace} key={i}>
                        <DropDownField
                          label={'Priority Order'}
                          inputValues={
                            handlers
                              ? getPriorityList(
                                  handlers,
                                  business.name,
                                  priorityName,
                                  contentMode
                                )
                              : [1]
                          }
                          name={'priorityOrder'}
                          onChange={handleChange}
                          value={values.priorityOrder}
                          error={touched.priorityOrder && errors.priorityOrder}
                          style={{
                            width: gridSpace === 12 ? '375px' : '270px',
                            marginBottom: '10px'
                          }}
                        />
                      </Grid>
                    );
                  }
                  return (
                    <Grid item xs={gridSpace} key={i}>
                      <TextFieldComponent
                        label={field.label}
                        name={field.name}
                        onChange={handleChange}
                        value={values[field.name]}
                        error={touched[field.name] && errors[field.name]}
                        style={{
                          marginBottom: '10px',
                          width: gridSpace === 12 ? '375px' : '270px'
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              {campaignCheck && (
                <Box style={{ marginTop: '10px' }}>
                  <FormControlLabel
                    label="Show Campaign Name in Report"
                    labelPlacement="start"
                    control={
                      <CustomSwitch
                        name="showCampaignNameInReport"
                        onChange={handleChange}
                        checked={values.showCampaignNameInReport}
                      />
                    }
                  />
                </Box>
              )}
              <div className="submitButton">
                <ButtonField
                  variant="contained"
                  label={'Done'}
                  type="submit"
                  style={{ background: '#F07663', color: 'white' }}
                />
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
